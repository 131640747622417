import {
  NO_STYLE,
  STATUS_RED,
  STATUS_ORANGE,
  MONTH_LIST,
  DAYS_OF_WEEK,
  TT_CLS_STATUS_OPEN,
  TT_CLS_STATUS_PENDING,
  TT_CLS_STATUS_RELEASED,
  TT_CLS_STATUS_INACTIVE,
  BATCH_STATUS_NAME,
  BLANK_LABEL,
  SS_ERROR_MSG_ROOMRATE_AMOUNT_VALIDATE,
  ERROR_MSG_VALIDATION_ALLOWED_NUMBERS,
  SS_ERROR_MSG_ROOM_TAX_AMOUNT_SHOULD_BE_LESS_THEN_ROOM_RATE,
  SS_ERROR_MSG_ROOM_TAX_AMOUNT_ROOM_RATE_REQUIRED,
  BLANKS_LABEL,
  MAX_AMT_LIMIT_MSG,
} from "./constants";
import {
  BATCH_STATUS_TYPE,
  GA_STATUS_COLOR_OPTIONS,
  BLANK_KEY,
  TT_STATUS_FILTER_OPTION,
  USER_ACCESS_LEVEL,
  USER_ROLE,
  BLANKS_KEY,
  STATUS_PROCERSSED_PAID,
  MAX_ADJ_COM_AMT_LIMIT,
} from "./config";
import { AUTH_API_PATH } from "./Api_URL";

export const getSession = (name) => {
  return sessionStorage.getItem(name);
};

export const setSession = (name, value) => {
  return sessionStorage.setItem(name, value);
};

export const removeSession = (name) => {
  return sessionStorage.removeItem(name);
};

export const buildPropertyDetails = ({ marriott_user }, selectedProperty) => {
  let propertyList = [],
    propCode = getSession("propCode")?.length ? getSession("propCode") : "",
    authInfo = {},
    pages = [];
  marriott_user.EID = marriott_user.EID.trim();

  if (marriott_user.abovePropertyUser && !selectedProperty) {
    const superAdminPropInfo = marriott_user.userAccess.find(
      (itm) => itm.functionCode === USER_ROLE.SUPER_ADMIN
    );
    if (superAdminPropInfo) {
      /* Super Admin : Function code 9CTA */
      const pageInfo = marriott_user.pageAccess.find(
        (itm) => itm.functionCode === superAdminPropInfo.functionCode
      );
      pages = pageInfo.pages;
    }
    authInfo = {
      authIndicator: Number(superAdminPropInfo?.authIndicator),
      propertyList: [],
      propCode,
      abovePropertyUser: marriott_user.abovePropertyUser,
      pages,
      admin: marriott_user.admin,
    };
    if (!superAdminPropInfo) {
      /* IPC User: Function code PIPC */
      const ipcPropInfo = marriott_user.userAccess.find(
        (itm) => itm.functionCode === USER_ROLE.IPC
      );
      if (ipcPropInfo) {
        const pageInfo = marriott_user.pageAccess.find(
          (itm) => itm.functionCode === ipcPropInfo.functionCode
        );
        pages = pageInfo.pages;
      }
      authInfo = {
        authIndicator: Number(ipcPropInfo.authIndicator),
        propertyList: [],
        propCode,
        abovePropertyUser: marriott_user.abovePropertyUser,
        pages,
        admin: marriott_user.admin,
        ipc: ipcPropInfo?.functionCode === USER_ROLE.IPC,
      };
    }
  } else {
    let propInfo,
      resGroupPropInfo,
      resGroupList = [];
    propCode = selectedProperty || propCode;
    if (propCode) {
      resGroupPropInfo = marriott_user.userAccess.find((respGroup) => {
        const hasProperty = respGroup.propertires.includes(propCode);
        if (hasProperty) {
          return respGroup;
        }
      });
    } else {
      resGroupPropInfo = marriott_user.userAccess.find(
        (itm) => itm.respGroupName.length
      );
    }
    marriott_user.userAccess.forEach((itm) => {
      propertyList = [...propertyList, ...itm.propertires];
      if (itm.respGroupName) {
        resGroupList.push(itm.respGroupName);
      }
    });
    propertyList = [...resGroupList, ...propertyList];
    if (resGroupPropInfo) {
      /* Property User: Response Group */
      const pageInfo = marriott_user.pageAccess.find(
        (itm) => itm.functionCode === resGroupPropInfo.functionCode
      );
      pages = pageInfo.pages;
      propInfo = resGroupPropInfo;
    } else {
      const resGroupPropInfo = marriott_user.userAccess.find(
        (itm) => itm.respGroupName.length
      );
      if (resGroupPropInfo) {
        /* Property User: Response Group */
        const pageInfo = marriott_user.pageAccess.find(
          (itm) => itm.functionCode === resGroupPropInfo.functionCode
        );
        pages = pageInfo.pages;
        propInfo = resGroupPropInfo;
      } else {
        /* Property User: Normal property user */
        const propUserPropInfo = marriott_user.userAccess.find(
          (itm) => itm.respGroupName === ""
        );
        if (propUserPropInfo) {
          const pageInfo = marriott_user.pageAccess.find(
            (itm) => itm.functionCode === propUserPropInfo.functionCode
          );
          pages = pageInfo.pages;
          propInfo = propUserPropInfo;
        }
      }
    }
    if (propInfo) {
      propCode =
        propCode || (propertyList.length <= 1 ? propertyList[0] : null);
      authInfo = {
        authIndicator: Number(propInfo.authIndicator),
        propertyList,
        propCode,
        abovePropertyUser: marriott_user.abovePropertyUser,
        pages,
        admin: marriott_user.admin,
      };
    }
  }
  setSession("propCode", propCode);
  const result = { ...marriott_user, ...authInfo };
  return result;
};

export const getRowDetailsById = (block_id) => (state) => {
  if (
    Object.keys(state.group.groupRowDetail).length &&
    state.group.groupRowDetail[block_id]
  ) {
    return state.group.groupRowDetail[block_id];
  }
  return {
    innerBlock: [],
    outerBlock: [],
    batches: [],
    groupRowDetailLoading: false,
    hasData: false,
  };
};

export const removeEmpty = (flatObj) => {
  if (Object.keys(flatObj).length) {
    for (const key in flatObj) {
      if (!(flatObj[key] && (flatObj[key] !== "" || flatObj[key] !== null))) {
        delete flatObj[key];
      }
    }
  }
  return flatObj;
};

export const compareAutoApprovalDate = (data) => {
  data = convertStringDateFormat(data);
  let autoApprovalDate = new Date(data).getDate();
  let autoApprovalMonth = new Date(data).getMonth();
  let autoApprovalYear = new Date(data).getFullYear() % 100;

  let currentDate = new Date().getDate();
  let currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear() % 100;

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  let nextDayDate = tomorrow.getDate();
  let nextDayMonth = tomorrow.getMonth();
  let nextDayYear = tomorrow.getFullYear() % 100;

  let newDateTime = new Date().getTime();
  let autoAppovalDateTime = new Date(data).getTime();

  if (
    parseInt(autoApprovalDate) === currentDate &&
    parseInt(autoApprovalMonth) === currentMonth &&
    parseInt(autoApprovalYear) === currentYear
  ) {
    return STATUS_RED;
  } else if (
    parseInt(autoApprovalDate) === nextDayDate &&
    parseInt(autoApprovalMonth) === nextDayMonth &&
    parseInt(autoApprovalYear) === nextDayYear
  ) {
    return STATUS_ORANGE;
  } else if (newDateTime > autoAppovalDateTime) {
    return STATUS_RED;
  } else {
    return NO_STYLE;
  }
};

export const getGRAStatusBG = (status, partiallyProcessedFlag) => {
  let statusDayStyle = "";
  if (
    !GA_STATUS_COLOR_OPTIONS.includes(status) ||
    (status === STATUS_PROCERSSED_PAID && partiallyProcessedFlag === "Y")
  ) {
    statusDayStyle = "grey-status-bg cursor-pointer";
  }
  return statusDayStyle;
};

export const getTransientStatusBG = (approvalDate, status) => {
  let statusDayStyle = NO_STYLE;
  if (
    status === BATCH_STATUS_TYPE.REVIEW ||
    status === BATCH_STATUS_TYPE.PENDING
  ) {
    statusDayStyle = compareAutoApprovalDate(approvalDate);
  }
  return statusDayStyle;
};
export const getTransientTaxStatusBG = (status) => {
  switch (status) {
    case TT_STATUS_FILTER_OPTION[0]:
      return TT_CLS_STATUS_OPEN;
    case TT_STATUS_FILTER_OPTION[1]:
      return TT_CLS_STATUS_PENDING;
    case TT_STATUS_FILTER_OPTION[2]:
      return TT_CLS_STATUS_RELEASED;
    case TT_STATUS_FILTER_OPTION[3]:
      return TT_CLS_STATUS_INACTIVE;
    default:
      return TT_CLS_STATUS_OPEN;
  }
};

export const downloadFile = async (file, fileName) => {
  const url = URL.createObjectURL(new Blob([file.data]));
  const outputFilename = `${fileName}.xlsx`;
  let link = document.createElement("a");
  link.href = url;
  link.download = url;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getSymbal = (urlPath) => {
  return urlPath.includes("?") ? "&" : "?";
};

export const buildExportURL = (data) => {
  let url = data.url;
  if (data.filterBy) {
    for (const filterKey in data.filterBy) {
      if (Object.hasOwn(data.filterBy, filterKey)) {
        const filterValue = data.filterBy[filterKey];
        if (
          typeof filterValue === "string" ||
          typeof filterValue === "boolean"
        ) {
          const symbal = getSymbal(url);
          url = `${url}${symbal}${filterKey}=${filterValue}`;
        } else if (filterValue?.length) {
          // eslint-disable-next-line no-loop-func
          // filterValue.map((filterItem) => {
          //   url = `${url}${symbal}${filterKey}=${filterItem}`;
          // });
          const symbal = getSymbal(url);
          url = `${url}${symbal}${filterKey}=${filterValue}`;
        } else {
          const symbal = getSymbal(url);
          url = `${url}${symbal}${filterKey}=`;
        }
      }
    }
  }
  if (data.currencyType) {
    const symbal = getSymbal(url);
    url = `${url}${symbal}currencyType=${data.currencyType}`;
  }
  return url;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (const element of ca) {
    let c = element;
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function gotoLogin(url) {
  if (url) {
    window.location.replace(`${url}`);
  } else {
    window.location.replace(`${AUTH_API_PATH}/auth`);
  }
}

export function clearSession() {
  sessionStorage.clear();
}

const doubleDigit = (val) => {
  return val < 10 ? `0${val}` : val;
};

export const convertStringDateFormat = (date) => {
  const newDate = date.split("-");
  const [newYear, newMonth, newDay] = newDate;
  return `${newMonth}/${newDay}/${newYear}`;
};

export const dateUtil = (date) => {
  let newDate;
  if (date) {
    if (typeof date === "string" && date.indexOf("-") > -1) {
      newDate = new Date(convertStringDateFormat(date));
    } else {
      newDate = new Date(date);
    }
  } else {
    newDate = new Date();
  }
  const [month, day, dayOfWeek, year] = [
    newDate.getMonth(),
    newDate.getDate(),
    newDate.getDay(),
    newDate.getFullYear(),
  ];
  const [hour, minutes, seconds] = [
    newDate.getHours(),
    newDate.getMinutes(),
    newDate.getSeconds(),
  ];
  let ampm = hour >= 12 ? "PM" : "AM";
  return { day, dayOfWeek, year, month, hour, minutes, seconds, ampm };
};

export const formatDate = (date, format) => {
  let dateObj = dateUtil(date);
  let newDate = format;
  if (newDate.match(/MMM/g)) {
    newDate = newDate.replace(/MMM/g, MONTH_LIST[dateObj.month]);
  } else if (newDate.match(/MM/g)) {
    newDate = newDate.replace(/MM/g, doubleDigit(dateObj.month + 1));
  }
  if (newDate.match(/DD/g)) {
    newDate = newDate.replace(/DD/g, doubleDigit(dateObj.day));
  }
  if (newDate.match(/dddd/g)) {
    newDate = newDate.replace(/dddd/g, DAYS_OF_WEEK[dateObj.dayOfWeek]);
  }
  if (newDate.match(/YYYY/g)) {
    newDate = newDate.replace(/YYYY/g, dateObj.year);
  } else if (newDate.match(/YY/g)) {
    newDate = newDate.replace(/YY/g, dateObj.year.toString().slice(2));
  }
  if (newDate.match(/HH/g)) {
    newDate = newDate.replace(/HH/g, doubleDigit(dateObj.hour));
  }
  if (newDate.match(/MM/g)) {
    newDate = newDate.replace(/MM/g, doubleDigit(dateObj.minutes));
  }
  if (newDate.match(/SS/g)) {
    newDate = newDate.replace(/SS/g, doubleDigit(dateObj.seconds));
  }
  if (newDate.match(/AMPM/g)) {
    newDate = newDate.replace(/AMPM/g, dateObj.ampm);
  }
  return newDate;
};

export const getActiveRoute = (path) => {
  const splitPathName = path.split("/");
  return splitPathName[1];
};

export const parseJwt = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const removeUrlParameter = (paramKey) => {
  let r = new URL(window.location.href);
  r.searchParams.delete(paramKey);
  window.location.href = r.href;
};

export const getSessionParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramVal = urlParams.get(param);
  return paramVal;
};

export const validateIsQ50 = (data) => {
  let addStayFlag = false;
  if (
    data?.centralizedInd === "N" &&
    (data?.propInterface === "0" || data?.propInterface === "1")
  ) {
    addStayFlag = true;
  }
  return addStayFlag;
};

export const validatePrivilege = (
  data,
  authIndicator,
  userId,
  selectedBatch
) => {
  let returnVal = true;
  const currentStatus = data.batchStatus.toUpperCase();
  if (
    currentStatus === BATCH_STATUS_NAME.REVIEW ||
    currentStatus === BATCH_STATUS_NAME.PENDING
  ) {
    if (selectedBatch && data.batchStatus !== selectedBatch.batchStatus) {
      returnVal = true;
      return returnVal;
    }
    if (authIndicator === USER_ACCESS_LEVEL.READ_ONLY) {
      returnVal = true;
    } else if (authIndicator === USER_ACCESS_LEVEL.REVIEW_ONLY) {
      if (currentStatus !== BATCH_STATUS_NAME.REVIEW) {
        returnVal = true;
      } else {
        return false;
      }
    } else if (authIndicator >= USER_ACCESS_LEVEL.REVIEW_AND_APPROVE) {
      if (currentStatus === BATCH_STATUS_NAME.PENDING) {
        if (data.reviewerId === userId) {
          returnVal = true;
        } else {
          returnVal = false;
        }
      } else if (currentStatus === BATCH_STATUS_NAME.REVIEW) {
        returnVal = false;
      } else {
        returnVal = false;
      }
    }
  }
  return returnVal;
};

export const replaceAll = (str = "", find = "") => {
  const re = new RegExp(find, "g");
  return str.replace(re, "");
};

export const differenceInDays = (date_1, date_2) => {
  let date1 = new Date(date_1);
  let date2 = new Date(date_2);
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  return Difference_In_Days;
};

export const convertObjToArray = (
  inputObj,
  returnType = "key",
  showBlanklabel = false
) => {
  const outputArray = [];
  for (const key in inputObj) {
    if (Object.hasOwn(inputObj, key)) {
      const element = inputObj[key];
      if (returnType === "key") {
        outputArray.push({
          value: key === BLANK_KEY ? "" : key,
          label: element.trim(),
        });
      } else if (returnType === "key-key") {
        outputArray.push({
          value: key === BLANK_KEY ? "" : key,
          label: key,
        });
      } else if (returnType === "value") {
        outputArray.push({ value: element.trim(), label: element.trim() });
      } else {
        outputArray.push({
          value: key === BLANK_KEY ? "" : key,
          label:
            key === "" || key === BLANK_KEY
              ? showBlanklabel
                ? ``
                : BLANK_LABEL
              : `${key} - ${element.trim()}`,
        });
      }
    }
  }
  return outputArray;
};

export const convertObjToArrayMultiSelect = (
  inputObj,
  returnType = "key",
  showBlanklabel = false
) => {
  const outputArray = [];
  for (const key in inputObj) {
    if (Object.hasOwn(inputObj, key)) {
      const element = inputObj[key];
      if (returnType === "key") {
        outputArray.push({
          value: key === BLANK_KEY ? "" : key,
          label: element.trim(),
        });
      } else if (returnType === "key-key") {
        outputArray.push({
          value: key === BLANK_KEY ? "" : key,
          label: key,
        });
      } else if (returnType === "value") {
        outputArray.push({ value: element.trim(), label: element.trim() });
      } else {
        outputArray.push({
          value: key === BLANK_KEY ? "" : key,
          label:
            key === "" || key === BLANK_KEY || key === BLANKS_KEY || key === " "
              ? showBlanklabel
                ? ``
                : BLANKS_LABEL
              : `${key} - ${element.trim()}`,
        });
      }
    }
  }
  return outputArray;
};

export const validateSession = () => {
  let newSessionID = getSessionParam("sessionId");
  if (newSessionID) {
    setSession("x-session-id", newSessionID);
    removeSession("accessToken");
    removeUrlParameter("sessionId");
    return null;
  }

  let sessionID = getSession("x-session-id");
  if (!sessionID) {
    gotoLogin();
  } else {
    const accessToken = getSession("accessToken");
    return accessToken;
  }
  return null;
};

export const removeHtml = (message) => {
  const regex = /(<([^>]+)>)/gi;
  const result = message.replace(regex, "");
  return result;
};

export const addToObject = (sourceObj, key, value, index) => {
  let temp = {};
  let i = 0;
  for (let prop in sourceObj) {
    if (sourceObj.hasOwnProperty(prop)) {
      if (i === index && key && value) {
        temp[key] = value;
      }
      temp[prop] = sourceObj[prop];
      i++;
    }
  }
  if (!index && key && value) {
    temp[key] = value;
  }
  return temp;
};

export const rateValidation = (value) => {
  let regexNumbersOnly = new RegExp("^[0-9.,]+$"),
    returnVal;
  if (regexNumbersOnly.test(value)) {
    const amt = Number(value.replace(/,/g, ""));
    if (amt > 0) {
      console.log("amt > ", amt, "    Max > ", MAX_ADJ_COM_AMT_LIMIT);
      if (Number(amt) <= MAX_ADJ_COM_AMT_LIMIT) {
        returnVal = true;
      } else {
        returnVal = MAX_AMT_LIMIT_MSG;
      }
    } else {
      returnVal = SS_ERROR_MSG_ROOMRATE_AMOUNT_VALIDATE;
    }
  } else {
    returnVal = ERROR_MSG_VALIDATION_ALLOWED_NUMBERS;
  }
  return returnVal;
};

export const rateTaxValidation = (value, roomRate) => {
  let regexNumbersOnly = new RegExp("^[0-9.,]+$"),
    returnVal;
  if (regexNumbersOnly.test(value)) {
    if (Number(value.replace(".", "").replace(/,/g, "")) >= 0) {
      if (roomRate?.length) {
        if (
          Number(value.replace(/,/g, "")) < Number(roomRate.replace(/,/g, ""))
        ) {
          returnVal = true;
        } else if (Number(value.replace(/,/g, "")) === 0) {
          returnVal = true;
        } else {
          returnVal =
            SS_ERROR_MSG_ROOM_TAX_AMOUNT_SHOULD_BE_LESS_THEN_ROOM_RATE;
        }
      } else {
        returnVal = SS_ERROR_MSG_ROOM_TAX_AMOUNT_ROOM_RATE_REQUIRED;
      }
    } else {
      returnVal = SS_ERROR_MSG_ROOMRATE_AMOUNT_VALIDATE;
    }
  } else {
    returnVal = ERROR_MSG_VALIDATION_ALLOWED_NUMBERS;
  }
  return returnVal;
};

export const getKeyByValue = (object, value) => {
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) return prop;
    }
  }
};

export const buildApproveReviewPayload = (selectedRow, userId) => {
  const batchStatusDetails = selectedRow
    ? selectedRow.map(({ propertyCode, batchDate, batchNum1, batchNum2 }) => {
        return { propertyCode, batchDate, batchNum1, batchNum2 };
      })
    : [];
  return { userId, batchStatusDetails };
};

export const scrollToTop = (top = 300) => {
  window.scrollTo({ top, behavior: "smooth" });
};

export const setExpiry = (sessionDurationInMin) => {
  let now = new Date();
  now.setMinutes(now.getMinutes() + Number(sessionDurationInMin));
  setSession("sessionExpiry", now);
};

export const formatAmount = (val, precision = 0) => {
  let rate = Number(val.replace(/,/g, ""));
  return rate.toFixed(precision);
};
