export const CURRENCY_TYPE = {
  PROPERTY: "Property",
  USD: "USD",
};
export const MONTH_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const USER_ROLE_REVIEWER = "reviwer";
export const USER_ROLE_APPROVER = "approver";

export const PLACEHOLDER_TXT_SELECT = "Select";

// Common - Labels & Content - Start

export const FILTER_LABEL_APPLY = "APPLY";
export const FILTER_LABEL_CANCEL_ALL = "Clear All";
export const PAGINATION_LABEL_ITEMS_PER_PAGE = "Items per page";
export const PAGINATION_LABEL_SHOWING = "Showing";
export const PAGINATION_LABEL_OF = "of";
export const PAGINATION_LABEL_RESULTS = "results";
export const PAGINATION_LABEL_STAYS = "stays";
export const DOWNLOAD_EXPORT_LABEL = ".XLS";
export const VIEW_ALL = "View All";
export const CURRENCY_CONST = {
  CURRENCY_TITLE: "Set currency displayed",
};
export const BATCH_SCHEDULED_MSG = `Scheduled batch process is running. User actions are disabled. We
appreciate your patience. Thank you!`;

// Batch overview type
export const BATCH_OVERVIEW_TYPE = {
  TRANSIENT_BATCH: "transientBatch",
  GROUP_BATCH: "GROUPBATCH",
  ALL: "ALL",
};

export const BLANK_LABEL = "BLANK";
export const BLANKS_LABEL = "(BLANKS)";
export const YES = "Y";

export const PROMPT_BTN_LABEL_OK = "Ok";
export const PROMPT_BTN_LABEL_CANCEL = "Cancel";
export const VOID_STAY_NOPAYREASON_CONFO_MSG =
  "Are you sure you want to delete this stay?";

export const PLACEHOLDER_CHANGE_PROPERTY = "PROPCODE";

export const MOD_TOOLTIP_LABEL = "Modified";
export const INVOICE_TOOLTIP_LABEL = "Invoice";
export const PROCESSED_TOOLTIP_LABEL = "Processed";
export const SUSPENSE_TOOLTIP_LABEL = "Suspense";
export const ACTIVATION_TOOLTIP_LABEL = "Activation";
export const COMMISSION_TOOLTIP_LABEL = "Commission";
export const FASTPAY_TOOLTIP_LABEL = "FastPay";
export const CANCELLED_TOOLTIP_LABEL = "Cancelled";
export const FILTER_TITLE_STATUS = "STATUS";
// Common - Labels & Content - End

//Error - Labels and Content - Start

export const ERROR_MESSAGE =
  "Oops Something went wrong. Please try again and if you still receive the error, contact ";
export const IATA_API_ERROR = "Oops Something went wrong. Please try again";
export const CTAC_SUPPORT = "CTAC support";
export const MAX_LENGTH_ERROR_MESSAGE = "Maximum Length exceeded";

//Error - Labels and Content -End

//Header - Label Start

export const HEADER_COMMISSION = "Manage Commissions";
export const HEADER_TRANSIENT_BATCHES = "Transient Batches";
export const HEADER_TRANSIENT_TAXES = "Transient Taxes";
export const HEADER_GROUP_BLOCKS = "Group Blocks";
export const HEADER_AUDIT = "Group Research & Audit";

//Header - Label End

//Admin - Label Start

export const ADMIN = "Admin";
export const BROADCAST_MESSAGES_TITLE = "Broadcast messages";
export const BROADCAST_SAVE_SUCCESS = "Message saved successfully";
export const SAVE_FAILED = "Save Failed";
export const SAVE_BTN_LABEL = "Save";

//Admin - Label End

// Landing - Labels & Content - Start

export const LANDING_NO_DATA_FOUND = "NO DATA FOUND";
export const NOT_APPLICABLE = "N/A";
export const LANDING_LABELS = {
  HEADER_MI: "MI",
  HEADER_COMMISSION_TOOL: "Commission Management Tool",
  FAQ: " FAQs",
  HEADER_CONTACT_US: "Contact Us",
  HEADER_ALERTS: "Alerts",
  HEADER_LOGOUT: "Logout",
  TANSIENT_OVERVIEW: "Transient Batch Overview",
  GROUP_OVERVIEW: "Group Blocks Overview",
  TRANSIENT_TAXES: "Transient Tax Records to Review",
  GROUP_EVENT_AUDIT: "Group Research & Audit",
};
export const WELCOME_TITLE = "Welcome to Marriott's Commission Management Tool";
export const WELCOME_MESSAGE =
  " Efficiently manage your property's commissions, ensuring intermediary partners are paid timely and accurately.";
export const BROADCAST_MESSAGE = `CTAC Training is available on the Digital Learning Zone search
"CTAC" for a list of courses. A CTAC Quick Reference Guide and
Business Process Job Aids are also available on the CTAC Home Page
on Marriott Global Source. For CTAC Application Support or
Questions, Open a SNOW ticket to "CTAC-HELP-DESK-Support" under
Application Support or call 800-626-3406.`;
export const AUDIT_DESC_START = "You currently have";
export const AUDIT_DESC_END =
  "intermediated group blocks arriving in the next thirty days.";
export const AUDIT_IATA =
  "- Check to ensure groups are set up correctly with an IATA";
export const AUDIT_RESEARCH = "- Research past groups";

export const AUDIT_CONTROL_OFFICE_MSG =
  "Control Office users have to select a Property Code to view details.";

export const GROUP_AUDIT_CONTROL_OFFICE_MSG =
  "Control Office users: Select a Property Code to view";

// Landing - Labels & Content - End

// Transient - Labels & Content - Start

export const TB_FILTER_TITLE = "Filters";
export const TB_FILTER_TITLE_MONTH = "BATCH MONTH";
export const TB_FILTER_TITLE_STATUS = "STATUS";
export const TB_FILTER_PLACEHOLDER_STATUS = "Selected Status";
export const FILTER_TITLE_MOD = "MODIFIED INDICATOR";
export const TB_ITEMSPERPAGE = 25;
export const REVIEW_APPROVE_SUCCESS_MSG =
  "Your selected batches have been reviewed. Keep up the good work!";
export const APPROVE_SUCCESS_MSG =
  "Your selected batches have been approved. Keep up the good work!";
export const BATCH_STATUS = {
  REVIEW: {
    HEADING: "Batches to Review",
    HEADING_BATCH: "Batch to Review",
    TITLE: "review",
  },
  APPROVE: {
    HEADING: "Batches to Approve",
    HEADING_APPROVE: "Batch to Approve",
    TITLE: "approve",
  },
  PENDING_FUNDING: { HEADING: "Pending Funding", TITLE: "pending" },
  TOTAL_COMMISSION: { HEADING: "Total Commissions", TITLE: "commision" },
  TOTAL_PROPERTY_AMOUNT: {
    HEADING: "Total Property Amount",
    TITLE: "totalAmount",
  },
  TOTAL_COMMISSION_TAX: {
    HEADING: "Total Commission Tax",
    TITLE: "totalCommissionTax",
  },
  TOTAL_STAYS: { HEADING: "Total Stays", TITLE: "totalStays" },
};
//Transient table headers
export const PROPERTY_CODE = "Prop. Code";
export const BATCH_ID = "Batch Id";
export const BATCH_DATE = "Batch Date";
export const STAYS = "Stays";
export const VIEW_STAYS = "View Stays";
export const PROPERTY_AMOUNT = "Prop. Amount";
export const CURRENCY = "Currency";
export const AUTO_APPROVE = "Auto-Approve Date";
export const STATUS = "Status";
export const MOD = "Mod";
export const REV_DATE = "Rev. Date";
export const REVIEWER = "Reviewer";
export const APPR_DATE = "Appr. Date";
export const APPROVER = "Approver";
export const FILTER_MONTH_PLACEHOLDER = "Select Month";
export const FILTER_MOD_PLACEHOLDER = "Selected Mod";
export const BATCH_STATUS_NAME = {
  REVIEW: "READY FOR REVIEW",
  PENDING: "READY FOR APPROVAL",
  ALL: "ALL",
};
export const ACTION_BAR_BTN_NAME = {
  FOR_APPROVAL: "Send for Approval",
  FOR_APPROVE: "Approve",
  CANCEL: "Cancel",
};
export const NO_STYLE = "no-style";
export const STATUS_RED = "status-red";
export const STATUS_ORANGE = "status-orange";
export const SYSTEM_APPROVED = "System Approved";

export const SAVE_CHANGES = "Save";
export const SAVE_ALL_CHANGES = "Save All";
export const ADD_A_STAY = "Add a Stay";

// Transient - Labels & Content - End

//Transient tax
export const TT_FILTER_TITLE_IATA = "IATA #";
export const TT_FILTER_TITLE_CONFIRMATION = "CONFIRMATION #";
export const TT_FILTER_TITLE_ARRIVAL_FROM = "ARRIVAL FROM";
export const TT_FILTER_TITLE_ARRIVAL_TO = "ARRIVAL TO";
export const TT_FILTER_TITLE_GUEST_NAME = "GUEST NAME";

export const RECORDS_TO_REVIEW = " Records to Review";
export const RECORD_TO_REVIEW = " Record to Review";
export const TB_TABLE_STAY_SELECTED = "Stay Selected";
export const TB_TABLE_BATCH_SELECTED = "Batch(s) Selected";
export const TT_TABLE_STAY_SELECTED = "Stay(s) Selected";
export const TT_TABLE_HEADER_IATA = "IATA#";
export const TT_TABLE_HEADER_GUEST_NAME = "Guest Name";
export const TT_TABLE_HEADER_CONFIRMATION = "Confirmation#";
export const TT_TABLE_HEADER_ARRIVAL_DATE = "Arrival Date";
export const TT_TABLE_HEADER_DEPARTURE_DATE = "Departure Date";
export const TT_TABLE_HEADER_ROOM_NIGHTS = "Room Nights";
export const TT_TABLE_HEADER_RATE = "Room Rate";
export const TT_TABLE_HEADER_REVENUE = "Room Revenue";
export const TT_ACTION_BAR_BTN_NAME = {
  RELEASE_TO_BATCH: "Release to Batch",
  UNRELEASE: "Unrelease",
  CANCEL: "Cancel",
};
export const TT_RELEASE_SUCCESS_MSG =
  "Great job! Your selected stay(s) will be included in your next batch";
export const TT_UNRELEASE_SUCCESS_MSG_START = "Success! The selected stay(s) ";
export const TT_UNRELEASE_SUCCESS_MSG_MIDDLE = "will not be included ";
export const TT_UNRELEASE_SUCCESS_MSG_END = "in the next day's batch";
export const TT_CLS_STATUS_OPEN = "open-status-bg";
export const TT_CLS_STATUS_PENDING = "pending-status-bg";
export const TT_CLS_STATUS_RELEASED = "released-status-bg";
export const TT_CLS_STATUS_INACTIVE = "inactive-status-bg";
export const IATA_ERROR = "Invalid IATA#";
export const TT_IATA_INACTIVE_ERROR = "Inactive IATA#";
export const TT_GUEST_NAME_ERROR = "Type at least 2 characters";
export const DATE_RANGE_FROM = "arrivalFrom";
export const DATE_RANGE_TO = "arrivalTo";
export const TRANSIENT_TAX = "transientTax";
export const TRANSIENT_TAX_CONTENT =
  "Select the stays for which you have received a tax invoice and release them to batch for review and approval. Reminder: Tax invoices for Onyx Reconciliation members are not listed below; they are available on the";
export const ONYX_TEXT = " Onyx Portal.";
// Transient Tax - Labels & Content - End

//stay summary - Labels & Content -Start

export const STAY_SUMMARY = "Stay Summary";
export const SS_RETURN_TO_BATCH = "Return to Batch";
export const SS_FILTER_TITLE_MARKET_SEGMENT = "MARKET SEGMENT";
export const SS_FILTER_TITLE_NO_PAY_REASON = "NO PAY REASON";
export const SS_FILTER_TITLE_FOLIO = "FOLIO INDICATOR";
export const SS_FILTER_TITLE_INVOICE_INDICATOR = "INVOICE INDICATOR";
export const SS_FILTER_TITLE_ARRIVAL_DATE = "ARRIVAL DATE";
export const SS_FILTER_TITLE_ADJUSTMENT_CODE = "ADJUSTMENT CODE";
export const SS_FILTER_TITLE_MOD = "MODIFIED INDICATOR";
export const SS_FILTER_BLANK = "BLANK";
export const SS_FILTER_SELECTED = "Selected";

export const SS_STAYS = "stays";
export const NO_PAY_SAVE_SUCCESS = "Stay(s) have been saved";
export const STAY_ADDED = "Stay added";
export const STAY_EDITED = "Stay successfully edited.";

// Transient - stay summary - Labels & Content - Start
export const TABLE_HEADER_FOLIO = "Folio";
export const TABLE_HEADER_INVOICE_REQ = "Inv";
export const TABLE_HEADER_MARKET_SEG = "Market Seg / Code";
export const TABLE_HEADER_COMM_TAX = "Comm Tax";
export const TABLE_HEADER_ADJUST_RSN = "Adjust RSN";
export const TABLE_HEADER_NO_PAY_RSN = "No Pay RSN";
export const TABLE_HEADER_PS = "PS";
export const TABLE_HEADER_IATA = "IATA#";
export const TABLE_HEADER_GUEST_NAME = "Guest Name";
export const TABLE_HEADER_CONFIRMATION = "Confirmation#";
export const TABLE_HEADER_ARRIVAL_DATE = "Arrival Date";
export const TABLE_HEADER_DEPARTURE_DATE = "Departure Date";
export const TABLE_HEADER_ROOM_NIGHTS = "Room Nights";
export const TABLE_HEADER_RATE = "Room Rate";
export const TABLE_HEADER_REVENUE = "Room Revenue";
export const TABLE_HEADER_PROPERTY_AMOUNT = "Property Amount";
export const ENTER_NEW_STAY = "Enter New Stay";
export const ENTER_IATA = "Enter the IATA number: ";
export const LABEL_IATA_NUMBER = "IATA NUMBER";
export const VALIDATE_IATA = "Validate IATA";
export const IATA_UPDATE_BTN = "Update";
export const TRAVEL_AGENCY_NAME = "TRAVEL AGENCY NAME";
export const IATA_PLACEHOLDER = "Add Text";
export const REVIEW_STAY_DETAILS = "Review Stay Details";
export const ENTER_STAY_DETAILS = "Enter the stay details";
export const LABEL_GUEST_NAME = "GUEST NAME";
export const LABEL_CONFO_NUMBER = "CONFIRMATION #";
export const LABEL_ARRIVAL_DATE = "ARRIVAL DATE";
export const LABEL_BOOKING_DATE = "BOOKING DATE";
export const LABEL_CHECK_OUT_DATE = "CHECK-OUT DATE";
export const LABEL_MARKET_CODE = "MARKET CODE";
export const LABEL_MARKET_SEGMENT = "MARKET SEGMENT";
export const LABEL_RATE_PLAN = "RATE PLAN";
export const LABEL_CORPORATE_ID = "CORPORATE ID";
export const LABEL_ROOM_RATE_ADR = "RATE/ ADR";
export const LABEL_ROOM_RATE_ADR_TAX_INC = "RATE/ ADR (Tax Inc)";
export const LABEL_ROOM_NIGTHS = "ROOM NIGHTS";
export const LABEL_NO_PAY_REASON = "NO PAY REASON (OPTIONAL)";
export const LABEL_ADJ_CODE = "ADJUSTMENT CODE";
export const LABEL_TAX_INCLUDED = "TAX INCLUSIVE? ";
export const LABEL_CURRENCY = "CURRENCY";
export const LABEL_BOOKING_SOURCE = "BOOKING SOURCE";
export const LABEL_ROOM_RATE_TAX_AMT = "ROOM RATE TAX AMOUNT";

export const SS_ERROR_MSG_CONFO_REQUIRED = "Enter Confirmation Number";
export const SS_ERROR_MSG_CONFO_VALIDATION_ACRS = "Should be 8 char length";
export const SS_ERROR_MSG_CONFO_VALIDATION_NO_ACRS = "Should be 10 char length";

export const SS_GUESTNAME_PLACEHOLDER = "Last Name/First Name";
export const SS_ERROR_MSG_GUESTNAME_REQUIRED = "Enter Guest Name";
export const SS_ERROR_MSG_GUESTNAME_VALIDATION =
  "Name must match pattern LASTNAME/ FIRSTNAME";

export const SS_ERROR_MSG_ARRIVAL_REQUIRED = "Select Arrival Date";
export const SS_ERROR_MSG_BOOKING_REQUIRED = "Select Booking Date";

export const SS_ERROR_MSG_MARKETSEGMENT_REQUIRED = "Select Market Segment";

export const SS_ERROR_MSG_RATEPLAN_REQUIRED = "Enter Rate Plan";

export const SS_ERROR_MSG_MARKETCODE_REQUIRED = "Enter Market Code";
export const SS_ERROR_MSG_MARKETCODE_INVALID = "Invalid Market Prefix";

export const SS_ERROR_MSG_ROOMNIGHTS_REQUIRED = "Enter Room Nights";
export const SS_ERROR_MSG_ROOMNIGHTS_INVALID =
  "Stay must end prior to today. Edit Room Nights or Arrival Date.";
export const ERROR_MSG_VALIDATION_ALLOWED_NUMBERS = "Value must be numeric";
export const SS_ERROR_MSG_ROOMNIGHTS_MAXNIGHTS_VALIDATION =
  "#Roomnights exceeds brand limit";
export const SS_ERROR_MSG_ROOMNIGHTS_ARRIVAL_REQUIRED = "Arrival Date Required";

export const SS_ERROR_MSG_ROOMRATE_REQUIRED = "Enter Room Rate";
export const SS_ERROR_MSG_ROOMRATE_VALIDATE_DECIMAL =
  "Value must be numeric and include decimal point";
export const SS_ERROR_MSG_ROOMRATE_VALIDATE_WITHOUT_DECIMAL =
  "Value must be numeric and no decimal point";
export const SS_ERROR_MSG_ROOMRATE_AMOUNT_VALIDATE =
  "Enter Amount Greater Than Zero";

export const SS_ERROR_MSG_BOOKING_SOURCE_REQUIRED = "Enter Booking Source";
export const SS_ERROR_MSG_BOOKING_SOURCE_VALIDATION =
  "Allowed characters A-Z, 0-9";

export const SS_ERROR_MSG_ROOM_TAX_AMOUNT_REQUIRED =
  "Enter Room Rate Tax Amount";

export const SS_ERROR_MSG_ROOM_TAX_AMOUNT_ROOM_RATE_REQUIRED =
  "Room Rate Required";
export const SS_ERROR_MSG_ROOM_TAX_AMOUNT_SHOULD_BE_LESS_THEN_ROOM_RATE =
  "Amount should be less than Room Rate";

// Transient - stay summary - Labels & Content - End

// Add/Edit Stay  - Start
export const STAY_ERROR_MAX_ROOM_NIGHTS_EXCEEDED =
  "Room Nights is higher than expected. Review and edit, if needed.";
export const STAY_ERROR_MAX_NIGHTS = "Number of nights exceeds brand limit";
export const STAY_ERROR_MAX_ROOM_RATE =
  "Room Rate is higher than expected. Review and edit, if needed";
export const STAY_SAVE_BTN_LABEL = "Save";
export const STAY_REVIEW_BTN_LABEL = "Review";
export const STAY_BACK_TO_EDIT_BTN_LABEL = "Back To Edit";
export const STAY_YES_BTN_LABEL = "Yes";
export const STAY_SAVE_DELETE_SUCCESS = "Stay deleted from batch.";
// Add/Edit Stay  - End

//Edit stay - Start

export const EDIT_STAY_DETAILS = "Edit Stay Details";
export const VIEW_STAY_DETAILS = "View Stay Details";
export const LABEL_COMMISSION_TAX = "COMMISSION TAX";
export const LABEL_COMMISSION_RATE = "PROPERTY COMMISSION RATE %";
export const LABEL_COMMISSION_TAX_RATE = "COMMISSION TAX RATE %";
export const LABEL_PROCESSED = "PROCESSED?";
export const LABEL_SHARE_INDICATOR = "SHARE INDICATOR";
export const LABEL_CONTIGUOUS_INDICATOR = "CONTIGUOUS INDICATOR";

export const ERROR_ARRIVAL_BEFORE_ACRS =
  "Arrival Date must be before conversion date.";
export const ERROR_ARRIVAL_AFTER_ACRS =
  "Arrival Date must be after conversion date.";
export const ERROR_ROOMNIGHT_AFTER_ACRS =
  "Stay must end prior to or on conversion date. Edit Room Nights or Arrival Date.";

//Edit stay - End

//Group Audit- Labels & Content - Start
export const GA_FILTER_LABEL_IATA = "GROUP IATA#";
export const GA_FILTER_LABEL_QUOTE = "QUOTE#";
export const GA_FILTER_LABEL_BLOCK_CODE = "GROUP BLOCK CODE";
export const GA_FILTER_LABEL_EVENT_START_FROM = "EVENT START FROM";
export const GA_FILTER_LABEL_EVENT_START_TO = "EVENT START TO";
export const GA_FILTER_LABEL_BLOCK_NAME = "GROUP BLOCK NAME";
export const GA_FILTER_LABEL_BLOCK_ID = "GROUP BLOCK ID";
export const GA_FILTER_LABEL_SUSPENSION_INDICATOR = "SUSPENSE INDICATOR";
export const GA_FILTER_LABEL_ACTIVATION_INDICATOR = "ACTIVATION INDICATOR";
export const GA_FILTER_LABEL_COMMISSION_INDICATOR = "COMMISSION INDICATOR";
export const GA_FILTER_LABEL_FAST_PAY_INDICATOR = "FASTPAY INDICATOR";
export const GA_FILTER_LABEL_BLANK_IATA = "NO IATA";
export const GA_FILTER_LABEL_SHOW_CXLD_BLOCKS = "SHOW CANCELLED BLOCKS";

export const GA_TABLE_HEADER_IATA = "Group IATA";
export const GA_TABLE_HEADER_BLOCK_NAME = "Group Block Name";
export const GA_TABLE_HEADER_QUOTE = "Quote Number";
export const GA_TABLE_HEADER_BLOCK_CODE = "Block Code";
export const GA_TABLE_HEADER_BLOCK_ID = "Block ID";
export const GA_TABLE_HEADER_PROPERTY = "Property";
export const GA_TABLE_HEADER_START_DATE = "Start Date";
export const GA_TABLE_HEADER_END_DATE = "End Date";
export const GA_TABLE_HEADER_ACT = "Act";
export const GA_TABLE_HEADER_COM = "Com";
export const GA_TABLE_HEADER_FP = "FP";
export const GA_TABLE_HEADER_SP = "SP";
export const GA_TABLE_HEADER_CXL = "CXL";
export const GA_TABLE_HEADER_LINK = "Link";

export const PAGINATION_LABEL_AUDIT = "group blocks";
export const GA_PAY_HISTORY = "Pay History";
export const GA_TABLE_HEADER_PAY_DATE = "Payment / Statement Date";
export const GA_TABLE_HEADER_PAYMENT = "Payment / Statement Number";
export const GA_TABLE_HEADER_COMMISSION_AMT = "Commission Amount";
export const GA_TABLE_HEADER_PAY_CURRENCY = "Pay Currency";
export const GA_PENDING = "Pending";
export const GA_CONTENT_FIRST_PART =
  "Audit upcoming groups to confirm IATA# is added to the group block. Instructions for updating the IATA# can be found on the";
export const GA_CONTENT_SECOND_PART =
  "page. You can also review status and payment details for individual groups.";
export const GA_PROGRAM_PROCEDURE = "Group Program Procedures";

//Group Block- Labels & Content - Start
export const GB_FILTER_LABEL_EVENT_START_DATE_FROM = "EVENT START DATE FROM";
export const GB_FILTER_LABEL_EVENT_START_DATE_TO = "EVENT START DATE TO";
export const GB_TABLE_HEADER_REVENUE = "Room Revenue";
export const GB_TABLE_HEADER_COMMISSION = "Commission";
export const GB_TABLE_HEADER_TAXES = "Taxes";
export const GB_TABLE_HEADER_TOTAL_COMMISSION = "Total Amount";
export const GB_TABLE_HEADER_OUTER_BLOCK = "Outside Block";
export const GB_TABLE_HEADER_REV_ID = "Reviewer";
export const GB_TABLE_HEADER_APPR_ID = "Approver";

//Add Adjustment - Start

export const ADD_ADJUSTMENT_TITLE = "Add Adjustment";
export const REVIEW_ADJUSTMENT_TITLE = "Review Adjustment";
export const REVIEW_BTN = "Review";
export const SAVE_BTN = "Save";

export const SELECT_REASON_ADJUSTMENT = "SELECT REASON FOR ADJUSTMENT";
export const ADJUSTMENT_REASON = "ADJUSTMENT REASON";
export const ADJUSTMENT_RATE_PER_ROOM_NIGHT = "ADJUSTMENT RATE PER ROOM NIGHT";
export const ADVANCED_COMMISSION_AMOUNT = "ADVANCED COMMISSION AMOUNT";
export const ADJUSTED_COMMISSION_REVENUE = "ADJUSTED COMMISSION REVENUE";
export const ADJUSTED_COMMISSION_AMOUNT = "ADJUSTED COMMISSION AMOUNT";
export const ADJUSTMENT_DESCRIPTION = "ADJUSTMENT DESCRIPTION";
export const COMMISSION_RATE = "COMMISSION RATE %";
export const TAX_RATE = "TAX RATE %";
export const ADJUSTMENT_COMMISSION_TAX = "ADJUSTMENT COMMISSION TAX";
export const ADJ_BATCH_ID = "BATCH ID";
export const ADJUSTMENT_REASON_VALIDATION_REQUIRED =
  "Adjustment Reason Is Required";

export const ADD_ADJ_ERROR_MSG_ADJUSTMENT_RATE_REQUIRED =
  "Enter Adjustment Rate";
export const ADD_ADJ_ERROR_MSG_COMMISSION_AMOUNT = "Enter Commission Amount";
export const ADD_ADJUSTMENT_SUCCESS = "Adjustment applied successfully";
export const ADD_ADJUSTMENT_FAILED = "Add Adjustment Failed";
//Add Adjustment - End

// Defer Payment - Start
export const DEFER_PAYMENT_INFO_TEXT =
  "If you select “No”, commission will not wait for 45 days, it will be released upon funding.";

// Defer Payment - End

export const COMM_NON_COMM_SUCCESS_MSG = "Selection Saved";
export const DEFER_PAYMENT_SUCCESS_MSG = "Selection Saved";

export const MAX_ADJ_ROOM_LIMIT_MSG = "Adj rm nts exceed sys lmt of";
export const MAX_ADJ_COM_AMT_LIMIT_MSG = "Adj com amt exceed sys lmt of";
export const MAX_AMT_LIMIT_MSG =
  "Room Rate is higher than expected. Review and edit, if needed";
export const RETURN_TO_GRA = "Return to Group Research & Audit";
export const NO_MODIFICATION_ALLOWED_MSG =
  "Update not allowed - batch belongs to another user.";

export const STATUS_NO_ACTIVITY = "No Activity";

//Link Stay Constants - Start
export const LINK_STAY_MSG =
  "These stays are linked; take all into consideration when making updates.";
export const GUEST_NAME_LINK_STAY_LABEL = "Guest Name";
export const CONFO_LINK_STAY_LABEL = "Confo #";
export const ARRIVAL_DATE_LINK_STAY_LABEL = "Arrival Date";
export const ROOM_NIGHTS_LINK_STAY_LABEL = "Room Nights";
export const CONTINUE_BTN_LABEL_LINK_STAY = "Continue";
export const CANCEL_BTN_LABEL_LINK_STAY = "Cancel";
//Link Stay Constants - End
