import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, OverlayTrigger, Popover, Row } from "react-bootstrap";
import DataTableServerSide from "../../../../components/datatableserverside/DataTableServerSide";
import userIcon from "../../../../assets/user-icon.png";
import {
  deferPaymentSave,
  fetchGroupBlockRowDetails,
  getDeferPayment,
  getPropertyDetails,
  saveBlockStatus,
  updateGroupBatchStatus,
  updateGroupBatchTableParams,
  updateGroupBlockIndex,
  updateCommNonCommStatus,
} from "../../../../store/feature/groupblock/groupBlockSlice";
import { GROUP_DETAIL } from "../../Constants";
import {
  buildApproveReviewPayload,
  convertObjToArray,
  getRowDetailsById,
  validatePrivilege,
} from "../../../../utils/utils";
import {
  TABLE_HEADER_ROOM_NIGHTS,
  GB_TABLE_HEADER_REVENUE,
  GB_TABLE_HEADER_COMMISSION,
  GB_TABLE_HEADER_TAXES,
  GB_TABLE_HEADER_TOTAL_COMMISSION,
  STATUS,
  BATCH_ID,
  BATCH_DATE,
  STAYS,
  PROPERTY_AMOUNT,
  CURRENCY,
  GB_TABLE_HEADER_OUTER_BLOCK,
  AUTO_APPROVE,
  REV_DATE,
  GB_TABLE_HEADER_REV_ID,
  APPR_DATE,
  GB_TABLE_HEADER_APPR_ID,
  LANDING_NO_DATA_FOUND,
  VIEW_STAYS,
  ACTION_BAR_BTN_NAME,
  BATCH_STATUS_NAME,
  TB_TABLE_BATCH_SELECTED,
  DEFER_PAYMENT_INFO_TEXT,
  PLACEHOLDER_TXT_SELECT,
  CURRENCY_TYPE,
  STATUS_NO_ACTIVITY,
  MOD,
} from "../../../../utils/constants";
import Pagination from "../../../../components/pagination/Pagination";
import {
  TB_STATUS_APPROVE,
  TB_STATUS_PENDING,
  GROUP_VIEW_STAYS_LINK,
  BATCH_STATUS_TYPE,
  USER_ACCESS_LEVEL,
  YES_KEY,
  ADJUSTMENT_REASON_OPTIONS,
  DEFER_PAYMENT_OPTIONS,
  BOOLEAN,
  INSIDE_BLOCK_COM_NON_COMMISSIONABLE_OPTIONS,
  NON_COMMISSIONABLE_KEY,
  COMMISSIONABLE_KEY,
  NO_KEY,
  INSIDE_BLOCK_NON_COMMISSIONABLE_OPTIONS,
  COMM_NON_COMM_KEY,
  GROUP_ROUTE_PATH,
} from "../../../../utils/config";
import ActionBar from "../../../../components/actionbar/ActionBar";
import {
  renderCellAmount,
  renderCellBatchID,
  renderCellBatchStatus,
  renderCellPropAmount,
  renderDateCell,
} from "../../../../utils/renderFunction";
import AddAdjustment from "../addadjustment/AddAdjustment";
import FormField from "../../../../components/formfield/FormField";
import {
  setPendingLinkBlockID,
  unsavedAlert,
} from "../../../../store/feature/landing/landingSlice";
import { useAlertMsg } from "../../../../utils/context/alertMessageContext";

export const renderCellApprover_Reviewer = (row, key) =>
  row?.original[`${key}Id`] ? (
    <OverlayTrigger
      delay={{ show: 50, hide: 400 }}
      placement="left"
      overlay={
        <Popover className="pop-over">
          <div className="font-12">
            <span>{row.original[`${key}Name`]}</span>
          </div>
        </Popover>
      }
    >
      <img
        width="18"
        height="18"
        alt={row.original[`${key}Name`]}
        src={userIcon}
      />
    </OverlayTrigger>
  ) : null;

export default function GroupBlockDetailView({ row, rowid }) {
  const dispatch = useDispatch();
  const { currencyType, userInfo } = useSelector((state) => state.landing);
  const {
    payloadForGroupBatch,
    propertyInfo,
    addAdjustmentSuccess,
    payloadForTable,
    groupRowDetail,
  } = useSelector((state) => state.group);
  const [showAdjuatmentModal, setShowAdjuatmentModal] = useState(false);
  const {
    innerBlock,
    outerBlock,
    batches,
    batchesTotalCount,
    getBatchError,
    deferPaymentInd,
    deferPaymentDetails,
  } = useSelector(getRowDetailsById(row.groupBlockId));
  const { alertContextDispatch } = useAlertMsg();
  let { pathname } = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [deferPayment, setDeferPayment] = useState();
  const [deferPayOriginalVal, setDeferPayOriginalVal] = useState();
  const [isDeferPaymentChanged, setIsDeferPaymentChanged] = useState(false);
  const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false);
  const [selectedRowStatus, setSelectedRowStatus] = useState("");
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [resetSelection, setResetSelection] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [batchesData, setBatchesData] = useState([]);
  const [deferMessage, setDeferMessage] = useState("");
  const [deferSuccess, setDeferSuccess] = useState(false);
  const [insideBlock, setInsideBlock] = useState([]);
  const [outsideBlock, setOutsideBlock] = useState([]);

  const customSort = (block) => {
    let topOrder = [];
    let lowerOrder = [];
    if (block?.length) {
      let data = [...block];
      data.forEach((item) => {
        if (item && item.status) {
          const status = item.status.trim();
          if (status === "Commissionable" || status === "Non-Commissionable") {
            topOrder.push(item);
          } else if (item?.status && item.status === "null") {
            lowerOrder.push({ ...item, status: STATUS_NO_ACTIVITY });
          } else {
            lowerOrder.push(item);
          }
        }
      });
      topOrder.sort(function (a, b) {
        return (a["status"] > b["status"]) - (a["status"] < b["status"]);
      });
      lowerOrder.sort(function (a, b) {
        return (a["status"] > b["status"]) - (a["status"] < b["status"]);
      });
    }
    return [...topOrder, ...lowerOrder];
  };

  useEffect(() => {
    if (getBatchError) {
      alertContextDispatch({
        type: "groupBatchListFailedShow",
        payload: {},
      });
    }
  }, [getBatchError]);

  useEffect(() => {
    const sortedBlocks = customSort(innerBlock);
    setInsideBlock([...sortedBlocks]);
  }, [innerBlock]);

  useEffect(() => {
    const sortedBlocks = customSort(outerBlock);
    setOutsideBlock([...sortedBlocks]);
  }, [outerBlock]);

  useEffect(() => {
    dispatch(unsavedAlert(isDeferPaymentChanged));
  }, [isDeferPaymentChanged]);

  const hasEditAccess = (() => {
    const status = row.status.toUpperCase();
    let hasAccess = false;
    if (
      userInfo?.authIndicator > USER_ACCESS_LEVEL.READ_ONLY &&
      (status === BATCH_STATUS_NAME.PENDING ||
        status === BATCH_STATUS_NAME.REVIEW) &&
      !(row.susPayIndicator === YES_KEY)
    ) {
      hasAccess = true;
    }
    return hasAccess;
  })();
  const isEditableDeferPayment =
    deferPaymentDetails?.deferPaymentUpdFlag &&
    hasEditAccess &&
    !(row.fpIndicator === YES_KEY);
  const updateGBBatchParam = (params) => {
    dispatch(
      updateGroupBatchTableParams({
        ...payloadForGroupBatch,
        ...params,
        group: pathname.includes(GROUP_ROUTE_PATH) ? true : false,
      })
    );
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    updateGBBatchParam({
      filterBy: {
        ...payloadForGroupBatch.filterBy,
        status: [...payloadForTable.filterBy.status],
        modIndicator: [...payloadForTable.filterBy.modIndicator],
      },
    });
  }, [row]);

  useEffect(() => {
    const indicator = BOOLEAN[deferPaymentInd] || deferPaymentInd;
    if (indicator) {
      setDeferPayment(indicator);
      setDeferPayOriginalVal(BOOLEAN[deferPaymentInd]);
    }
  }, [deferPaymentInd]);

  let batchOverviewTimer;
  useEffect(() => {
    if (batchOverviewTimer) {
      clearTimeout(batchOverviewTimer);
    }
    batchOverviewTimer = setTimeout(() => {
      loadCompData();
    }, 800);
    return () => {
      if (batchOverviewTimer) {
        clearTimeout(batchOverviewTimer);
      }
    };
  }, [row]);

  const loadGroupBatches = () => {
    dispatch(
      fetchGroupBlockRowDetails({
        propertyCode: row.propertyCode,
        mhSeqNum: row.groupBlockId,
        groupRowDetail: row,
        params: payloadForGroupBatch,
      })
    );
  };
  let batchTimer;
  useEffect(() => {
    clearTimeout(batchTimer);
    batchTimer = setTimeout(() => {
      if (row) {
        loadGroupBatches();
      }
    }, 100);
    return () => {
      clearTimeout(batchTimer);
    };
  }, [refreshData]);

  const loadCompData = () => {
    dispatch(
      getDeferPayment({
        blockId: row.groupBlockId,
        propertyCode: row.propertyCode,
      })
    );
    if (!(propertyInfo && propertyInfo[row.propertyCode])) {
      dispatch(getPropertyDetails(row.propertyCode));
    }
    dispatch(updateGroupBlockIndex(rowid));
  };

  useEffect(() => {
    if (addAdjustmentSuccess) {
      setShowAdjuatmentModal(false);
    }
  }, [addAdjustmentSuccess]);

  useEffect(() => {
    setResetSelection(!resetSelection);
    const makeDataOutput = makeData(batches, selectedRows);
    const isSelectAllDisabled = checkIsSelectAllDisabled(batches);
    setIsSelectAllDisabled(isSelectAllDisabled);
    setBatchesData(makeDataOutput);
  }, [batches]);

  const makeData = (batches, rowSelection) => {
    if (rowSelection?.length > 0) {
      setSelectedRowStatus(rowSelection[0]?.batchStatus);
      return batches && batches.length
        ? batches.map((data) => ({
            ...data,
            uniqueId: `batch-${data.batchNum1.padStart(
              4,
              "0"
            )}${data.batchNum2.padStart(4, "0")}`,
            isDisabled: validatePrivilege(
              data,
              userInfo?.authIndicator,
              userInfo?.EID,
              rowSelection[0]
            ),
          }))
        : [];
    }
    return batches && batches.length
      ? batches.map((data) => ({
          ...data,
          uniqueId: `batch-${data.batchNum1.padStart(
            4,
            "0"
          )}${data.batchNum2.padStart(4, "0")}`,
          isDisabled: validatePrivilege(
            data,
            userInfo?.authIndicator,
            userInfo?.EID
          ),
        }))
      : [];
  };

  const checkIsSelectAllDisabled = (block) => {
    let disableCheckbox = false;
    let blockArray = block
      ? block.map((item) => {
          return item.batchStatus.toUpperCase();
        })
      : [];
    const noOfUniqueStatuses = [...new Set(blockArray)].length;
    if (userInfo?.authIndicator <= USER_ACCESS_LEVEL.READ_ONLY) {
      disableCheckbox = true;
    } else if (userInfo?.authIndicator === USER_ACCESS_LEVEL.REVIEW_ONLY) {
      if (noOfUniqueStatuses > 1) {
        disableCheckbox = true;
      } else if (!blockArray.includes(BATCH_STATUS_NAME.REVIEW)) {
        disableCheckbox = true;
      }
    } else if (
      userInfo?.authIndicator >= USER_ACCESS_LEVEL.REVIEW_AND_APPROVE
    ) {
      if (noOfUniqueStatuses > 1) {
        disableCheckbox = true;
      } else if (noOfUniqueStatuses === 1) {
        if (
          !(
            blockArray.includes(BATCH_STATUS_NAME.PENDING) ||
            blockArray.includes(BATCH_STATUS_NAME.REVIEW)
          )
        ) {
          disableCheckbox = true;
        } else {
          disableCheckbox = block.some((data) => {
            return data.reviewerId === userInfo?.EID;
          });
          return disableCheckbox;
        }
      }
    }
    return disableCheckbox;
  };

  const onRowSelect = (data) => {
    setSelectedRowCount(data?.length);
    setSelectedRows(data);
    const makeDataOp = makeData(batchesData, data);
    setBatchesData(makeDataOp);
    if (data?.length) {
      dispatch(setPendingLinkBlockID(""));
    }
  };
  let timeId;
  const updateDeferPayment = async () => {
    setIsDeferPaymentChanged(false); // Disable save button
    const responce = await deferPaymentSave({
      deferPayment: DEFER_PAYMENT_OPTIONS[deferPayment],
      requestParam: {
        propertyCode: row.propertyCode,
        mhSeqNum: row.groupBlockId,
      },
    });
    if (responce) {
      setDeferMessage(responce.message);
      setDeferSuccess(responce.success);

      if (!responce.success) {
        const indicator = BOOLEAN[deferPaymentInd] || deferPaymentInd;
        setDeferPayment(indicator);
      } else {
        setDeferPayOriginalVal(deferPayment);
      }
      if (timeId) {
        clearTimeout(timeId);
      }
      timeId = setTimeout(() => {
        setDeferMessage(""); // Clear succes/Error msg
        clearTimeout(timeId);
      }, 10000);
    }
  };

  const RenderCellStatus = (row, blockRowData) => {
    const originalStatus = row?.original.status.trim();
    let cascadeFlag;
    const [statusNopay, setStatusNopay] = useState(
      COMM_NON_COMM_KEY[originalStatus]
    );
    const [enableSave, setEnableSave] = useState(false);
    const { pendingLinkBlockID } = useSelector((state) => state.landing);
    const isEditable = hasEditAccess;
    useEffect(() => {
      setEnableSave(statusNopay !== COMM_NON_COMM_KEY[originalStatus]);
      dispatch(unsavedAlert(statusNopay !== COMM_NON_COMM_KEY[originalStatus]));
      if (statusNopay !== COMM_NON_COMM_KEY[originalStatus]) {
        alertContextDispatch({
          type: "CommNonCommHide",
        });
      }
      dispatch(updateCommNonCommStatus(false));
    }, [statusNopay]);

    useEffect(() => {
      setStatusNopay(COMM_NON_COMM_KEY[originalStatus]);
      setEnableSave(false);
    }, [row]);

    const updateBlockStatus = (value) => {
      setStatusNopay(value);
      if (pendingLinkBlockID?.length) {
        dispatch(setPendingLinkBlockID(""));
      }
    };

    const saveBlockStatusAction = async () => {
      setEnableSave(false);
      const { groupBlockId, groupBlockCode, susPayIndicator, propertyCode } =
        blockRowData;
      const requestParam = {
        noPayReason: statusNopay,
        mhSequenceNumber: groupBlockId,
        mhCode: groupBlockCode,
        userId: userInfo?.EID,
        susPayIndicator: susPayIndicator,
        cascadeFlag,
      };
      const responce = await saveBlockStatus({
        requestParam,
        propertyCode,
      });
      dispatch(updateCommNonCommStatus(responce.success));
      alertContextDispatch({
        type: "CommNonCommShow",
        payload: {
          message: responce.message,
          show: true,
          variant: responce.success ? "success" : "danger",
        },
      });
      setEnableSave(true);
      if (!responce.success) {
        setStatusNopay(COMM_NON_COMM_KEY[originalStatus]);
      }
    };
    if (
      originalStatus === COMMISSIONABLE_KEY ||
      originalStatus === NON_COMMISSIONABLE_KEY
    ) {
      let options;
      if (originalStatus === COMMISSIONABLE_KEY) {
        const tempOption = { ...INSIDE_BLOCK_COM_NON_COMMISSIONABLE_OPTIONS };
        if (blockRowData?.fpIndicator === YES_KEY) {
          delete tempOption.MCHK;
          delete tempOption.MMPP;
        }
        cascadeFlag = YES_KEY;
        options = convertObjToArray(tempOption, "key");
      } else {
        options = convertObjToArray(
          { ...INSIDE_BLOCK_NON_COMMISSIONABLE_OPTIONS },
          "key"
        );
        cascadeFlag = NO_KEY;
      }

      return (
        <div className="form-item inside_block_edit_reason">
          <FormField
            type={"selectv2"}
            onChange={
              isEditable
                ? (value) => {
                    updateBlockStatus(value);
                  }
                : () => {}
            }
            defaultValue={statusNopay}
            value={statusNopay}
            className={"cascading_select_box float-start"}
            disabled={!isEditable}
            options={options}
          ></FormField>
          <Button
            variant="link"
            className="save_all_icon save_all_stay float-start ms-2"
            onClick={saveBlockStatusAction}
            disabled={!enableSave}
          >
            Save
          </Button>
        </div>
      );
    } else {
      const status =
        row?.original.status === "null"
          ? STATUS_NO_ACTIVITY
          : row?.original.status;
      return <div>{status}</div>;
    }
  };

  const renderCellViewStay = ({ row }) => {
    const stays = Number(row?.original.stays);
    if (stays) {
      return (
        <Link
          className="view-stays"
          to={`${GROUP_VIEW_STAYS_LINK}${row?.original?.propertyCode}/${row?.original?.batchNum1}/${row?.original?.batchNum2}`}
        >
          {VIEW_STAYS}
        </Link>
      );
    } else {
      return (
        <Button variant="link" disabled={true} className="view-stays m-0 p-0">
          {VIEW_STAYS}
        </Button>
      );
    }
  };

  const columnInnerBlock = useMemo(
    () => [
      {
        accessorKey: "roomNights",
        header: () => TABLE_HEADER_ROOM_NIGHTS,
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 100,
      },
      {
        accessorKey: "revenue",
        header: () => GB_TABLE_HEADER_REVENUE,
        className: "header_right_align",
        cell: ({ row }) => renderCellAmount(row, "revenue", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 120,
      },
      {
        accessorKey: "commissionAmount",
        header: () => GB_TABLE_HEADER_COMMISSION,
        className: "header_right_align",
        cell: ({ row }) =>
          renderCellAmount(row, "commissionAmount", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 120,
      },
      {
        accessorKey: "taxAmount",
        header: () => GB_TABLE_HEADER_TAXES,
        className: "header_right_align",
        cell: ({ row }) => renderCellAmount(row, "taxAmount", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 100,
      },
      {
        accessorKey: "totalAmount",
        header: () => GB_TABLE_HEADER_TOTAL_COMMISSION,
        className: "header_right_align",
        cell: ({ row }) => renderCellAmount(row, "totalAmount", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 120,
      },
      {
        accessorKey: "status",
        header: () => STATUS,
        footer: (props) => props.column.id,
        cell: (cellProps) => RenderCellStatus(cellProps.row, row),
        enableSorting: false,
        width: 400,
      },
    ],
    [currencyType]
  );

  const columnOutsideBlock = useMemo(
    () => [
      {
        accessorKey: "roomNights",
        header: () => TABLE_HEADER_ROOM_NIGHTS,
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 100,
      },
      {
        accessorKey: "revenue",
        header: () => GB_TABLE_HEADER_REVENUE,
        className: "header_right_align",
        cell: ({ row }) => renderCellAmount(row, "revenue", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 120,
      },
      {
        accessorKey: "commissionAmount",
        header: () => GB_TABLE_HEADER_COMMISSION,
        className: "header_right_align",
        cell: ({ row }) =>
          renderCellAmount(row, "commissionAmount", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 120,
      },
      {
        accessorKey: "taxAmount",
        header: () => GB_TABLE_HEADER_TAXES,
        className: "header_right_align",
        cell: ({ row }) => renderCellAmount(row, "taxAmount", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 100,
      },
      {
        accessorKey: "totalAmount",
        header: () => GB_TABLE_HEADER_TOTAL_COMMISSION,
        className: "header_right_align",
        cell: ({ row }) => renderCellAmount(row, "totalAmount", currencyType),
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 120,
      },
      {
        accessorKey: "status",
        header: () => STATUS,
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          const status =
            row?.original.status === "null"
              ? STATUS_NO_ACTIVITY
              : row?.original.status;
          return <div>{status}</div>;
        },
        enableSorting: false,
        width: 300,
      },
    ],
    [currencyType]
  );

  const addAdjustment = () => {
    setShowAdjuatmentModal(true);
    dispatch(setPendingLinkBlockID(""));
  };

  const enableAddAdjustment = useMemo(() => {
    let canEnable = false;
    const authIndicator = userInfo?.authIndicator;
    const onlyReviewOrPending =
      row?.status === BATCH_STATUS_TYPE.REVIEW ||
      row?.status === BATCH_STATUS_TYPE.PENDING;
    const hasProcessed =
      row?.reviewerId === userInfo?.EID
        ? true
        : row?.reviewerId?.length
        ? false
        : true;

    const adjustOption = { ...ADJUSTMENT_REASON_OPTIONS };
    if (row.fpIndicator === YES_KEY) {
      delete adjustOption.APAD;
    }
    row?.insideList.forEach((insideBlock) => {
      if (insideBlock.adjustCode && adjustOption[insideBlock.adjustCode]) {
        delete adjustOption[insideBlock.adjustCode];
      }
    });
    if (
      authIndicator > USER_ACCESS_LEVEL.READ_ONLY &&
      hasProcessed &&
      row.susPayIndicator !== YES_KEY &&
      onlyReviewOrPending &&
      Object.keys(adjustOption).length
    ) {
      canEnable = true;
    }
    return canEnable;
  }, [row, userInfo]);

  const insideBlockTableActionButtons = useMemo(() => {
    return {
      start: enableAddAdjustment
        ? [
            {
              label: "Add Adjustment",
              actionEvent: addAdjustment,
              className: `add_icon add_stay`,
            },
          ]
        : [],
      end: [],
    };
  }, [row]);

  const columnBatches = useMemo(
    () => [
      {
        accessorKey: "batchID",
        header: () => BATCH_ID,
        sortDescFirst: false,
        cell: renderCellBatchID,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "batchDate",
        header: () => BATCH_DATE,
        cell: ({ row }) => renderDateCell(row, "batchDate"),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "stays",
        header: () => STAYS,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "View Stays",
        header: () => VIEW_STAYS,
        cell: renderCellViewStay,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: "propertyAmount",
        header: () => PROPERTY_AMOUNT,
        cell: ({ row }) => renderCellPropAmount(row, currencyType),
        className: "header_right_align",
        footer: (props) => props.column.id,
        currencyType,
      },
      {
        accessorKey: "currency",
        header: () => CURRENCY,
        footer: (props) => props.column.id,
        cell: ({ row }) => (
          <span>
            {currencyType === CURRENCY_TYPE.USD
              ? CURRENCY_TYPE.USD
              : row.original.currency}
          </span>
        ),
      },
      {
        accessorKey: "autoApprovalDate",
        header: () => AUTO_APPROVE,
        cell: ({ row }) => renderDateCell(row, "autoApprovalDate"),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "outsideBlankInd",
        header: () => GB_TABLE_HEADER_OUTER_BLOCK,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "batchStatus",
        header: () => STATUS,
        cell: renderCellBatchStatus,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "modIndicator",
        header: () => MOD,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "reviewDate",
        header: () => REV_DATE,
        cell: ({ row }) => renderDateCell(row, "reviewDate"),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "reviewerId",
        header: () => GB_TABLE_HEADER_REV_ID,
        cell: ({ row }) => renderCellApprover_Reviewer(row, "reviewer"),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "approvalDate",
        header: () => APPR_DATE,
        cell: ({ row }) => renderDateCell(row, "approvalDate"),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "approverId",
        header: () => GB_TABLE_HEADER_APPR_ID,
        cell: ({ row }) => renderCellApprover_Reviewer(row, "approver"),
        footer: (props) => props.column.id,
      },
    ],
    [currencyType]
  );

  const applySort = (sortBy) => {
    if (sortBy) {
      updateGBBatchParam({
        ...payloadForGroupBatch,
        ...{ sortBy: { ...sortBy } },
      });
    }
  };

  const onApprove = (actionStatus) => {
    if (actionStatus) {
      const updateTBReqBody = buildApproveReviewPayload(
        selectedRows,
        userInfo?.EID
      );
      const group = pathname.includes(GROUP_ROUTE_PATH) ? true : false;
      dispatch(
        updateGroupBatchStatus(actionStatus, { ...updateTBReqBody, group })
      );
    }
  };

  const onCancelClick = () => {
    setResetSelection(!resetSelection);
  };

  const handleAddAdjustmentClose = () => {
    setShowAdjuatmentModal(false);
  };

  const deferPaymentChanged = (value) => {
    setDeferMessage(""); // Clear Success/Error msgs
    dispatch(setPendingLinkBlockID(""));
    if (value.length) {
      if (deferPayOriginalVal === value) {
        setIsDeferPaymentChanged(false);
      } else {
        setIsDeferPaymentChanged(true); // Enable deferpayment Save button
      }
    } else {
      setIsDeferPaymentChanged(false);
    }
    setDeferPayment(value);
  };

  return (
    <div className="ps-4">
      <Row>
        {insideBlock?.length ? (
          <>
            <h6 className="weight-700">{GROUP_DETAIL.ROOMS_INSIDE_TITLE}</h6>
            <DataTableServerSide
              className={"inside_block_table"}
              data={insideBlock}
              column={columnInnerBlock}
              fixedWidthColumn={true}
              actionButtons={insideBlockTableActionButtons}
            />
          </>
        ) : null}
      </Row>
      <Row className="pt-4">
        {outsideBlock?.length ? (
          <>
            <h6 className="weight-700">{GROUP_DETAIL.ROOMS_OUTSIDE_TITLE}</h6>
            <DataTableServerSide
              className={"outside_block_table"}
              data={outsideBlock}
              fixedWidthColumn={true}
              column={columnOutsideBlock}
            />
          </>
        ) : null}
      </Row>
      <Row className="pt-4">
        <h5 className="weight-700 font-16 p-2 details_block_header">
          {GROUP_DETAIL.DEFER_PAYMENT_TITLE} | Block ID: {row.groupBlockId}
        </h5>
        <div className="d-flex justify-content-start align-items-start py-3 ps-2">
          <p className="pt-2 ps-0 font-16">{GROUP_DETAIL.DEFER_PAYMENT_QA}</p>
          <div className="px-3 form-item">
            <FormField
              className="select-dropdown"
              type="selectv2"
              placeholder={PLACEHOLDER_TXT_SELECT}
              options={convertObjToArray(DEFER_PAYMENT_OPTIONS, "key-key")}
              defaultValue={deferPayment}
              disabled={!isEditableDeferPayment}
              onChange={
                isEditableDeferPayment
                  ? (value) => {
                      deferPaymentChanged(value);
                    }
                  : () => {}
              }
            />
          </div>
          <Button
            disabled={!isDeferPaymentChanged}
            className="curved-corner"
            onClick={!isDeferPaymentChanged ? () => {} : updateDeferPayment}
            varient="primary"
          >
            Save
          </Button>
          <p
            className={`${
              deferSuccess ? "success_color" : "error_color"
            } float-start ms-2 pt-2 `}
          >
            {deferMessage}
          </p>
        </div>
        <p className="font-12 deferpayment_info_text ps-2">
          {DEFER_PAYMENT_INFO_TEXT}
        </p>
      </Row>
      <Row>
        <h6 className="weight-700 ps-2">{GROUP_DETAIL.BATCH_TITLE}</h6>
        <ActionBar
          //showActionBar={false}
          showActionBar={selectedRowCount !== 0}
          message={`${selectedRowCount} ${TB_TABLE_BATCH_SELECTED}`}
        >
          <div className=" bd-highlight">
            {selectedRowStatus &&
            selectedRowStatus.toUpperCase() === BATCH_STATUS_NAME?.REVIEW ? (
              <Button
                className="action-btn weight-400"
                onClick={() => onApprove(TB_STATUS_PENDING)}
                variant="outline-success"
              >
                {ACTION_BAR_BTN_NAME?.FOR_APPROVAL}
              </Button>
            ) : (
              <Button
                className="action-btn weight-400"
                onClick={() => onApprove(TB_STATUS_APPROVE)}
                variant="outline-success"
              >
                {ACTION_BAR_BTN_NAME?.FOR_APPROVE}
              </Button>
            )}
            <Button
              className="btn-link action-cancel-btn p-0"
              onClick={() => onCancelClick()}
              variant="outline-success"
            >
              {ACTION_BAR_BTN_NAME.CANCEL}
            </Button>
          </div>
        </ActionBar>
        {batchesData?.length ? (
          <DataTableServerSide
            data={batchesData}
            column={columnBatches}
            sortCallback={applySort}
            enableCheckboxSelection={true}
            selectedRowCallback={onRowSelect}
            isSelectAllDisabled={isSelectAllDisabled}
            resetSelection={resetSelection}
          />
        ) : null}
        {batchesData?.length ? (
          <div>
            <Pagination
              className="pagination-bar"
              currentPage={payloadForGroupBatch.pageNumber}
              totalCount={batchesTotalCount}
              pageSize={payloadForGroupBatch.pageSize}
              onItemPerPageChange={(pageSize, resetPage) => {
                if (resetPage) {
                  updateGBBatchParam({
                    ...payloadForGroupBatch,
                    pageNumber: resetPage,
                    pageSize,
                  });
                } else {
                  updateGBBatchParam({
                    ...payloadForGroupBatch,
                    pageNumber: payloadForGroupBatch.pageNumber,
                    pageSize,
                  });
                }
              }}
              onPageChange={(page) => {
                updateGBBatchParam({
                  ...payloadForGroupBatch,
                  pageNumber: page,
                  pageSize: payloadForGroupBatch.pageSize,
                });
              }}
            />
          </div>
        ) : null}
        {batchesData.length === 0 ? (
          <p className="ms-2">{LANDING_NO_DATA_FOUND}</p>
        ) : null}
      </Row>
      {showAdjuatmentModal && (
        <AddAdjustment
          modalShow={showAdjuatmentModal}
          handleAddAdjustmentClose={handleAddAdjustmentClose}
          rowData={row}
        />
      )}
    </div>
  );
}
