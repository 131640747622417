import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import StaySummaryBatchOverview from "../../components/staysummarybatchoverview/StaySummaryBatchOverview";
import SummaryFilter from "./component/summaryfilter/SummaryFilter";
import CurrencyButton from "../../components/currencybutton/CurrencyButton";
import LoadingBar from "../../components/loadingbar/LoadingBar";
import userIcon from "../../assets/user-icon.png";
import {
  fetchStaySummaryOverview,
  fetchStaySummeryDetails,
  updateStaySummaryDetailParams,
  updateBatchParam,
  fetchSummeryBlock,
  getPropertyDetails,
  updateIataUpdateFlag,
  fetchStaySummFilterOption,
  getMasterData,
  updateNoPayReason,
  saveNopayData,
  updateAddStaySaveStatus,
  noPaySaveSuccess,
  noPaySaveFailed,
  clearNoPayReason,
  updateEditSaveStatus,
  updateEditReviewStatus,
} from "../../store/feature/staysummary/staySummarySlice";
import {
  APPROVER,
  APPR_DATE,
  AUTO_APPROVE,
  BATCH_DATE,
  BATCH_ID,
  CURRENCY,
  CURRENCY_TYPE,
  DOWNLOAD_EXPORT_LABEL,
  LABEL_NO_PAY_REASON,
  LANDING_NO_DATA_FOUND,
  MOD,
  PAGINATION_LABEL_STAYS,
  PROPERTY_AMOUNT,
  PROPERTY_CODE,
  REVIEWER,
  REV_DATE,
  SAVE_ALL_CHANGES,
  SS_RETURN_TO_BATCH,
  SS_STAYS,
  STATUS,
  STAYS,
  STAY_SUMMARY,
  SYSTEM_APPROVED,
  TABLE_HEADER_ADJUST_RSN,
  TABLE_HEADER_ARRIVAL_DATE,
  TABLE_HEADER_COMM_TAX,
  TABLE_HEADER_CONFIRMATION,
  TABLE_HEADER_FOLIO,
  TABLE_HEADER_GUEST_NAME,
  TABLE_HEADER_IATA,
  TABLE_HEADER_INVOICE_REQ,
  TABLE_HEADER_MARKET_SEG,
  TABLE_HEADER_NO_PAY_RSN,
  TABLE_HEADER_PROPERTY_AMOUNT,
  TABLE_HEADER_PS,
  TABLE_HEADER_RATE,
  TABLE_HEADER_REVENUE,
  TABLE_HEADER_ROOM_NIGHTS,
  MOD_TOOLTIP_LABEL,
  INVOICE_TOOLTIP_LABEL,
  PROCESSED_TOOLTIP_LABEL,
  NO_MODIFICATION_ALLOWED_MSG,
  GB_TABLE_HEADER_OUTER_BLOCK,
} from "../../utils/constants";
import "./staySummary.scss";
import returnToBatch from "../../assets/return_to_batch.png";
import InfoBar from "../../components/infobar/InfoBar";
import {
  buildExportURL,
  convertObjToArray,
  formatDate,
  scrollToTop,
} from "../../utils/utils";
import {
  AUTO_APPROVAL_ID,
  BATCH_STATUS_TYPE,
  CURRENCY_CONST_PROPERTY,
  INVOICE_INDICATOR_KEY,
  STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD,
  STAY_SUMMERY_PS,
  YES_KEY,
  ADD_STAY_SAVE_RESET,
  EDIT_STAY_SAVE_RESET,
  USER_LOCATION,
  USER_ACCESS_LEVEL,
  GROUP_ROUTE_PATH,
} from "../../utils/config";
import { OverlayTrigger, Popover } from "react-bootstrap";
import DataTableServerSide from "../../components/datatableserverside/DataTableServerSide";
import Pagination from "../../components/pagination/Pagination";
import {
  renderCellBatchID,
  renderCellBatchStatus,
  renderCellPropAmount,
} from "../../utils/renderFunction";
import AddStayModal from "./component/AddStayModal";
import {
  getDownloadFile,
  unsavedAlert,
  updateCurrancy,
} from "../../store/feature/landing/landingSlice";
import FormField from "../../components/formfield/FormField";
import Prompt from "../../components/prompt/Prompt";
import EditStay from "./component/EditStay";
import { API_URL } from "../../utils/Api_URL";
import LinkedStayModel from "./component/LinkedStayModel";
import { useAlertMsg } from "../../utils/context/alertMessageContext";

const renderCellBatchDate = ({ row }) =>
  row?.original?.batchDate
    ? formatDate(row?.original?.batchDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellAutoApprovalDate = ({ row }) =>
  row?.original?.autoApprovalDate
    ? formatDate(row?.original?.autoApprovalDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellReviewDate = ({ row }) =>
  row?.original?.reviewDate
    ? formatDate(row?.original?.reviewDate, "DDMMMYY").toUpperCase()
    : null;

export const renderCellApprover_Reviewer = (row, key) =>
  row?.original[`${key}Id`] ? (
    <OverlayTrigger
      delay={{ show: 50, hide: 400 }}
      placement="left"
      overlay={
        <Popover className="pop-over">
          <div className="font-12">
            <span>{row.original[`${key}Name`]}</span>
          </div>
        </Popover>
      }
    >
      <img
        width="18"
        height="18"
        alt={row.original[`${key}Name`]}
        src={userIcon}
      />
    </OverlayTrigger>
  ) : null;

const renderCellApprovalDate = ({ row }) =>
  row?.original?.approvalDate
    ? formatDate(row?.original?.approvalDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellGuestName = ({ row }) => <div>{row.original.guestName}</div>;

const renderCellArrivalDate = ({ row }) =>
  row?.original?.arrivalDate
    ? formatDate(row?.original?.arrivalDate, "DDMMMYY").toUpperCase()
    : null;

const renderCellMarketSegment = ({ row }) => (
  <div className="me-2">{row.original.marketSegment}</div>
);

const renderCellRoomRate = (row, currencyType) => (
  <div className="align-right">
    {currencyType === CURRENCY_TYPE.USD
      ? row.original.roomRateUsd
      : row.original.roomRate}
  </div>
);

const renderCellRoomRevenue = (row, currencyType) => (
  <div className="align-right">
    {currencyType === CURRENCY_TYPE.USD
      ? row.original.roomRevenueUsd
      : row.original.roomRevenue}
  </div>
);

const renderCellPropertyAmount = (row, currencyType) => (
  <div className="align-right">
    {currencyType === CURRENCY_TYPE.USD
      ? row.original.propertyAmountUsd
      : row.original.propertyAmount}
  </div>
);

const renderCellCommTax = (row, currencyType) => (
  <div className="align-right">
    {currencyType === CURRENCY_TYPE.USD
      ? row.original.commTaxUsd
      : row.original.commTax}
  </div>
);

const renderMod = ({ row }) => (
  <div className="align-center">{row.original.modIndicator}</div>
);

const renderCellPs = ({ row }) => <div>{row.original.psIndicator}</div>;

const renderCellInv = ({ row }) => (
  <div>{row.original.wsInvInd === YES_KEY ? row.original.wsInvInd : ""}</div>
);

export default function StaySummary() {
  const {
    showStaySummaryLoader,
    staySummaryOverview,
    overviewInternalError,
    serverError,
    batchTableInternalError,
    stayBatchDetail,
    staySummaryDetail,
    staySummaryDetailTotalCount,
    stayTablePayload,
    batchTablePayload,
    isQ50Prop,
    masterData,
    noPayModifiedSaveData,
    addStaySaveStatus,
    propertyDetails,
    noPaySaveSuccessFlag,
    noPaySaveFailedFlag,
    editStaySaveStatus,
  } = useSelector((state) => state.staySummary);
  const { alertContextState, alertContextDispatch } = useAlertMsg();
  const { currencyType, userInfo } = useSelector((state) => state.landing);
  const transientSlice = useSelector((state) => state.transient);
  const groupSlice = useSelector((state) => state.group);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyCode, bNum1, bNum2 } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showLinkedStay, setShowLinkedStay] = useState(false);
  const [transStaySummaryData, setTransStaySummaryData] = useState([]);
  const [enableSaveAll, setEnableSaveAll] = useState(false);
  const [enablePrompt, setEnablePrompt] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  const [linkRowData, setLinkRowData] = useState({});
  const isGroupBlock = pathname.includes(GROUP_ROUTE_PATH);

  useEffect(() => {
    return () => {
      alertContextDispatch({
        type: "staySummeryAddStaySuccessHide",
      });

      alertContextDispatch({
        type: "staySummeryEditStaySuccessHide",
      });

      alertContextDispatch({
        type: "staySummeryNoPaySaveErrorHide",
      });

      alertContextDispatch({
        type: "staySummeryNoPaySaveSuccessHide",
      });

      alertContextDispatch({
        type: "staySummeryServerErrorHide",
      });

      alertContextDispatch({
        type: "staySummeryBatchTableErrorHide",
      });
      dispatch(noPaySaveSuccess(false));
    };
  }, []);

  const enableEditStay = () => {
    let canEnable = false;
    const onlyReviewOrPending =
      stayBatchDetail?.batchStatus === BATCH_STATUS_TYPE.REVIEW ||
      stayBatchDetail?.batchStatus === BATCH_STATUS_TYPE.PENDING;
    const hasProcessed =
      stayBatchDetail?.reviewerId === userInfo?.EID
        ? true
        : stayBatchDetail?.reviewerId?.length
        ? false
        : true;
    const authIndicator = userInfo?.authIndicator;

    if (
      onlyReviewOrPending &&
      hasProcessed &&
      authIndicator > USER_ACCESS_LEVEL.READ_ONLY
    ) {
      canEnable = true;
    }
    return canEnable;
  };

  const showNoModification = useMemo(() => {
    let canEnable = false;
    const onlyPending =
      stayBatchDetail?.batchStatus === BATCH_STATUS_TYPE.PENDING;
    const hasProcessed =
      stayBatchDetail?.reviewerId === userInfo?.EID ? true : false;
    const authIndicator = userInfo?.authIndicator;

    if (
      onlyPending &&
      !hasProcessed &&
      authIndicator > USER_ACCESS_LEVEL.READ_ONLY
    ) {
      canEnable = true;
    }
    return canEnable;
  }, [stayBatchDetail]);

  useEffect(() => {
    dispatch(clearNoPayReason({}));
  }, [transientSlice.payloadForTable, groupSlice.payloadForTable]);

  useEffect(() => {
    if (staySummaryDetail.length) {
      const updateSummaryData = staySummaryDetail.map((data) => {
        const enableEdit = enableEditStay();
        const noPayReason =
          noPayModifiedSaveData?.[data.sequenceNumber]?.noPayReason;
        return typeof noPayReason !== "undefined"
          ? {
              ...data,
              enableSave: true,
              noPayRsn: noPayReason,
              enableEdit,
            }
          : { ...data, enableSave: false, enableEdit };
      });
      setTransStaySummaryData(updateSummaryData);
      setEnableSaveAll(
        noPayModifiedSaveData && Object.keys(noPayModifiedSaveData).length >= 1
      );
    } else {
      setTransStaySummaryData([]);
    }
  }, [
    noPayModifiedSaveData,
    staySummaryDetail,
    stayBatchDetail,
    isQ50Prop,
    userInfo,
  ]);

  useEffect(() => {
    updateTSSDetailParams({ ...STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD });
    dispatch(updateCurrancy(CURRENCY_CONST_PROPERTY));
    const group = pathname.includes(GROUP_ROUTE_PATH) ? true : false;
    dispatch(updateBatchParam({ bNum1, bNum2, group }));
    return () => {
      dispatch(clearNoPayReason({}));
    };
  }, []);

  useEffect(() => {
    dispatch(getPropertyDetails(propertyCode));
    dispatch(
      getMasterData({
        propertyCode: propertyCode,
        batchNum1: bNum1,
        batchNum2: bNum2,
      })
    );
  }, []);

  let summaryOverviewTimer;
  const refreshTransientSummaryOverviewData = () => {
    const summaryOverviewReqBody = {
      batchNum1: bNum1,
      batchNum2: bNum2,
      currencyType: currencyType,
      filterBy: stayTablePayload.filterBy,
      group: isGroupBlock,
    };
    dispatch(fetchStaySummaryOverview(propertyCode, summaryOverviewReqBody));
  };

  useEffect(() => {
    if (summaryOverviewTimer) {
      clearTimeout(summaryOverviewTimer);
    }
    summaryOverviewTimer = setTimeout(() => {
      refreshTransientSummaryOverviewData();
    }, 50);
    return () => {
      if (summaryOverviewTimer) {
        clearTimeout(summaryOverviewTimer);
      }
    };
  }, [propertyCode, stayTablePayload.filterBy]);

  let transStaySummaryDetailTimer;
  const refreshTBStaySummeryTableData = () => {
    let payloadForStayTable = {
      ...stayTablePayload,
      currencyType: currencyType,
      group: isGroupBlock,
    };
    if (propertyCode) {
      dispatch(
        fetchStaySummeryDetails({
          payloadForStayTable,
          propertyCode,
        })
      );
      if (transStaySummaryDetailTimer) {
        clearTimeout(transStaySummaryDetailTimer);
      }
    }
  };
  const refreshTBTableData = () => {
    let bNum = bNum1 + bNum2;
    if (batchTablePayload.filterBy.batchId === bNum) {
      let payloadForBatchTable = {
        ...batchTablePayload,
        ...{ filterBy: { ...batchTablePayload.filterBy, status: [] } },
        currencyType: currencyType,
        group: isGroupBlock,
      };
      dispatch(fetchSummeryBlock({ payloadForBatchTable, propertyCode }));
    }
  };

  useEffect(() => {
    refreshTBTableData();
  }, [batchTablePayload, propertyCode]);

  const refreshTransStaySummFilterOptionData = () => {
    if (bNum1 && propertyCode && bNum2) {
      dispatch(
        fetchStaySummFilterOption({
          payload: { batchNum1: bNum1, batchNum2: bNum2, group: isGroupBlock },
          propertyCode,
        })
      );
    }
  };

  useEffect(() => {
    refreshTransStaySummFilterOptionData();
  }, [bNum1, propertyCode, bNum2]);

  useEffect(() => {
    if (transStaySummaryDetailTimer) {
      clearTimeout(transStaySummaryDetailTimer);
    }
    transStaySummaryDetailTimer = setTimeout(() => {
      refreshTBStaySummeryTableData();
    }, 50);
    return () => {
      if (transStaySummaryDetailTimer) {
        clearTimeout(transStaySummaryDetailTimer);
      }
    };
  }, [stayTablePayload, propertyCode]);

  const resetAddStaySaveAction = () => {
    dispatch(updateAddStaySaveStatus({ ...ADD_STAY_SAVE_RESET }));
  };

  const resetEditStaySaveAction = () => {
    dispatch(updateEditSaveStatus({ ...EDIT_STAY_SAVE_RESET }));
  };

  useEffect(() => {
    /* Refresh After Add / Edit Stay */
    if (addStaySaveStatus.success) {
      refreshTransientSummaryOverviewData();
      refreshTBStaySummeryTableData();
      refreshTBTableData();
      refreshTransStaySummFilterOptionData();
      resetAddStaySaveAction();
      setModalShow(false);
      alertContextDispatch({
        type: "staySummeryAddStaySuccessShow",
      });
    }
  }, [addStaySaveStatus.success]);

  useEffect(() => {
    /* Refresh After Add / Edit Stay */
    if (editStaySaveStatus.success) {
      refreshTransientSummaryOverviewData();
      refreshTBStaySummeryTableData();
      refreshTBTableData();
      refreshTransStaySummFilterOptionData();
      setShowEditModal(false);
      alertContextDispatch({
        type: "staySummeryEditStaySuccessShow",
        payload: {
          message: editStaySaveStatus.message,
        },
      });
    }
    //setEditStaySuccess(editStaySaveStatus.success);
  }, [editStaySaveStatus]);

  useEffect(() => {
    if (!alertContextState.staySummeryEditStaySuccess.show) {
      hideEditstaySuccess();
    }
  }, [alertContextState.staySummeryEditStaySuccess]);

  useEffect(() => {
    if (noPaySaveFailedFlag) {
      alertContextDispatch({
        type: "staySummeryNoPaySaveErrorShow",
        payload: {
          message: noPaySaveFailedFlag,
        },
      });
    }
  }, [noPaySaveFailedFlag]);

  useEffect(() => {
    if (!alertContextState.staySummeryNoPaySaveError.show) {
      hideNoPaySaveFailed();
    }
  }, [alertContextState.staySummeryNoPaySaveError]);

  useEffect(() => {
    /* Refresh After Edit No Pay Resson Success */
    if (noPaySaveSuccessFlag) {
      refreshTransientSummaryOverviewData();
      refreshTBStaySummeryTableData();
      refreshTBTableData();
      refreshTransStaySummFilterOptionData();
      dispatch(unsavedAlert(false));
      alertContextDispatch({
        type: "staySummeryNoPaySaveSuccessShow",
      });
    }
  }, [noPaySaveSuccessFlag]);

  const updateSave = (row, noPay) => {
    let updateSaveData = {
      [row.original.sequenceNumber]: {
        sequenceNumber: row.original.sequenceNumber,
        noPayReason: noPay,
        invoiceIndicator: row.original.wsInvInd,
        bookingDate: row.original.bookingDate,
        roomRateTax: row.original.roomRateTax,
      },
    };
    dispatch(
      updateNoPayReason({
        ...updateSaveData,
      })
    );
    dispatch(noPaySaveSuccess(false));
    dispatch(noPaySaveFailed(""));
    dispatch(unsavedAlert(true));
  };

  const RenderCellNoPay = ({ row }) => {
    const [modifiedReason, setModifiedReason] = useState("");
    useEffect(() => {
      setModifiedReason(row.original.noPayRsn);
    }, [row.original.noPayRsn]);
    let noPayReasonsList = { ...masterData.noPayReasons };
    delete noPayReasonsList.VOID;
    if (!isGroupBlock) {
      // Removed FSPD for transient
      delete noPayReasonsList.FSPD;
    }
    if (row.original.adjRsn === "NMNT") {
      noPayReasonsList = {
        BLANK: "",
        NCMS: masterData.noPayReasons.NCMS,
        CXLD: masterData.noPayReasons.CXLD,
        NSHW: masterData.noPayReasons.NSHW,
      };
    }
    console.log("row", row);

    const enableEdit = enableEditStay();
    return (
      <div className="form-item">
        {enableEdit ? (
          <FormField
            name={`nopay-${row.original.confirmationNumber}`}
            type="selectv2"
            label={LABEL_NO_PAY_REASON}
            value={modifiedReason}
            defaultValue={modifiedReason}
            className="w-100 nopay-dropdown"
            options={convertObjToArray(noPayReasonsList, "keyValue", true)}
            onChange={(noPay) => {
              setModifiedReason(noPay);
              updateSave(row, noPay);
            }}
          />
        ) : (
          <span>{row.original.noPayRsn}</span>
        )}
      </div>
    );
  };

  const columnBatch = useMemo(() => {
    const config = [
      {
        accessorKey: "propertyCode",
        header: () => PROPERTY_CODE,
        footer: (props) => props.column.id,
        enableSorting: false,
        width: 80,
      },
      {
        accessorKey: "batchId",
        header: () => BATCH_ID,
        enableSorting: false,
        cell: renderCellBatchID,
        footer: (props) => props.column.id,
        width: 80,
      },
      {
        accessorKey: "batchDate",
        header: () => BATCH_DATE,
        enableSorting: false,
        footer: (props) => props.column.id,
        cell: renderCellBatchDate,
        width: 80,
      },
      {
        accessorKey: "stays",
        header: () => STAYS,
        enableSorting: false,
        footer: (props) => props.column.id,
        width: 50,
      },
      {
        accessorKey: "propertyAmount",
        header: () => PROPERTY_AMOUNT,
        className: "header_right_align",
        enableSorting: false,
        cell: ({ row }) => renderCellPropAmount(row, currencyType),
        footer: (props) => props.column.id,
        width: 90,
      },
      {
        accessorKey: "currency",
        header: () => CURRENCY,
        enableSorting: false,
        cell: ({ row }) => (
          <span>
            {currencyType === CURRENCY_TYPE.USD
              ? CURRENCY_TYPE.USD
              : row.original.currency}
          </span>
        ),
        footer: (props) => props.column.id,
        width: 80,
      },
      {
        accessorKey: "autoApprovalDate",
        header: () => AUTO_APPROVE,
        enableSorting: false,
        footer: (props) => props.column.id,
        cell: renderCellAutoApprovalDate,
        width: 130,
      },
      {
        accessorKey: "batchStatus",
        header: () => STATUS,
        enableSorting: false,
        cell: renderCellBatchStatus,
        footer: (props) => props.column.id,
        width: 110,
      },
      {
        accessorKey: "modIndicator",
        header: () => MOD,
        enableSorting: false,
        footer: (props) => props.column.id,
        tooltip: MOD_TOOLTIP_LABEL,
        width: 60,
      },
      {
        accessorKey: "reviewDate",
        header: () => REV_DATE,
        enableSorting: false,
        footer: (props) => props.column.id,
        cell: renderCellReviewDate,
        width: 80,
      },
      {
        accessorKey: "reviewerId",
        header: () => REVIEWER,
        enableSorting: false,
        cell: ({ row }) => renderCellApprover_Reviewer(row, "reviewer"),
        footer: (props) => props.column.id,
        width: 75,
      },
      {
        accessorKey: "approvalDate",
        header: () => APPR_DATE,
        enableSorting: false,
        footer: (props) => props.column.id,
        cell: renderCellApprovalDate,
        width: 80,
      },
      {
        accessorKey: "approverId",
        header: () => APPROVER,
        enableSorting: false,
        cell: ({ row }) => renderCellApprover_Reviewer(row, "approver"),
        footer: (props) => props.column.id,
        width: 75,
      },
    ];
    if (isGroupBlock) {
      config.splice(7, 0, {
        accessorKey: "outsideBlankInd",
        header: () => GB_TABLE_HEADER_OUTER_BLOCK,
        footer: (props) => props.column.id,
        width: 95,
        enableSorting: false,
      });
    }
    return config;
  }, [currencyType]);

  const columnStay = useMemo(() => {
    const config = [
      {
        accessorKey: "modIndicator",
        header: () => MOD,
        footer: (props) => props.column.id,
        cell: renderMod,
        tooltip: MOD_TOOLTIP_LABEL,
        width: 48,
      },
      {
        accessorKey: "folioIndicator",
        header: () => TABLE_HEADER_FOLIO,
        footer: (props) => props.column.id,
        width: 48,
      },
      {
        accessorKey: "iata",
        header: () => TABLE_HEADER_IATA,
        footer: (props) => props.column.id,
        width: 80,
      },
      {
        accessorKey: "guestName",
        header: () => TABLE_HEADER_GUEST_NAME,
        footer: (props) => props.column.id,
        width: 180,
        cell: renderCellGuestName,
      },
      {
        accessorKey: "confirmationNumber",
        header: () => TABLE_HEADER_CONFIRMATION,
        width: 110,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "arrivalDate",
        header: () => TABLE_HEADER_ARRIVAL_DATE,
        width: 80,
        footer: (props) => props.column.id,
        cell: renderCellArrivalDate,
      },
      {
        accessorKey: "roomNights",
        header: () => TABLE_HEADER_ROOM_NIGHTS,
        width: 60,
        sortDescFirst: false,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "marketSegment",
        header: () => TABLE_HEADER_MARKET_SEG,
        width: 120,
        sortDescFirst: false,
        footer: (props) => props.column.id,
        cell: renderCellMarketSegment,
      },
      {
        accessorKey: "roomRate",
        header: () => TABLE_HEADER_RATE,
        className: "header_right_align",
        width: 95,
        footer: (props) => props.column.id,
        cell: ({ row }) => renderCellRoomRate(row, currencyType),
      },
      {
        accessorKey: "roomRevenue",
        header: () => TABLE_HEADER_REVENUE,
        className: "header_right_align",
        width: 95,
        footer: (props) => props.column.id,
        cell: ({ row }) => renderCellRoomRevenue(row, currencyType),
      },
      {
        accessorKey: "propertyAmount",
        header: () => TABLE_HEADER_PROPERTY_AMOUNT,
        className: "header_right_align",
        width: 100,
        footer: (props) => props.column.id,
        cell: ({ row }) => renderCellPropertyAmount(row, currencyType),
      },
      {
        accessorKey: "commTax",
        header: () => TABLE_HEADER_COMM_TAX,
        className: "header_right_align",
        width: 85,
        sortDescFirst: false,
        footer: (props) => props.column.id,
        cell: ({ row }) => renderCellCommTax(row, currencyType),
      },
      {
        accessorKey: "wsInvInd",
        header: () => TABLE_HEADER_INVOICE_REQ,
        footer: (props) => props.column.id,
        tooltip: INVOICE_TOOLTIP_LABEL,
        width: 48,
        cell: renderCellInv,
      },
      {
        accessorKey: "adjRsn",
        header: () => TABLE_HEADER_ADJUST_RSN,
        width: 80,
        sortDescFirst: false,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "noPayRsn",
        header: () => TABLE_HEADER_NO_PAY_RSN,
        width: 78,
        sortDescFirst: false,
        footer: (props) => props.column.id,
        cell: RenderCellNoPay,
      },
    ];
    if (
      !(
        stayBatchDetail.batchStatus === BATCH_STATUS_TYPE.REVIEW ||
        stayBatchDetail.batchStatus === BATCH_STATUS_TYPE.PENDING
      )
    ) {
      config.push({
        accessorKey: "psIndicator",
        header: () => TABLE_HEADER_PS,
        tooltip: PROCESSED_TOOLTIP_LABEL,
        width: 48,
        sortDescFirst: false,
        footer: (props) => props.column.id,
        cell: renderCellPs,
      });
    }
    return config;
  }, [masterData, currencyType, stayBatchDetail]);

  const handleAddStayClose = () => {
    dispatch(updateIataUpdateFlag([false, ""]));
    resetAddStaySaveAction();
    setModalShow(false);
  };
  const handleEditStayClose = () => {
    resetEditStaySaveAction();
    setShowEditModal(false);
  };

  const closeLinkStay = () => {
    setShowLinkedStay(false);
  };

  const addStay = () => {
    dispatch(clearNoPayReason({}));
    setModalShow(true);
  };

  const saveAllStay = () => {
    let payload = {
      sequenceNumber: "",
      saveData: {
        propertyCode: propertyCode,
        batchNum1: bNum1,
        batchNum2: bNum2,
        noPayDetails: Object.entries(noPayModifiedSaveData).map(
          (entry) => entry[1]
        ),
      },
    };
    if (!pathname.includes(GROUP_ROUTE_PATH)) {
      payload.saveData.transientStay = true;
    }
    dispatch(saveNopayData(payload));
  };

  const saveNoPay = (row) => {
    dispatch(noPaySaveSuccess(false));
    if (row && noPayModifiedSaveData?.[row.sequenceNumber]) {
      let payload = {
        sequenceNumber: row.sequenceNumber,
        saveData: {
          propertyCode: propertyCode,
          batchNum1: bNum1,
          batchNum2: bNum2,
          noPayDetails: [
            {
              ...noPayModifiedSaveData?.[row.sequenceNumber],
            },
          ],
        },
      };
      if (!pathname.includes(GROUP_ROUTE_PATH)) {
        payload.saveData.transientStay = true;
      }
      dispatch(saveNopayData(payload));
    }
  };

  const hideNoPaySaveSuccess = () => {
    dispatch(noPaySaveSuccess(false));
  };

  const hideNoPaySaveFailed = () => {
    dispatch(noPaySaveFailed(""));
  };

  const hideEditstaySuccess = () => {
    resetEditStaySaveAction();
    dispatch(
      updateEditReviewStatus({
        success: false,
        apiError: true,
        message: ``,
      })
    );
  };

  useEffect(() => {
    hideNoPaySaveFailed();
    hideNoPaySaveSuccess();
    alertContextDispatch({
      type: "staySummeryAddStaySuccessHide",
    });
  }, []);

  const noPayPromptAction = (action) => {
    setEnablePrompt(false);
  };

  const editStay = (row) => {
    //row.shareIndicator = YES_KEY;
    if (row.shareIndicator === YES_KEY) {
      setShowLinkedStay(true);
      setLinkRowData(row);
    } else {
      openEditStay(row);
    }
  };

  const openEditStay = (row) => {
    dispatch(clearNoPayReason({}));
    setShowEditModal(true);
    setEditRowData({
      ...row,
      outsideBlankInd: stayBatchDetail.outsideBlankInd,
    });
  };

  const applySort = (sortBy) => {
    sortBy = sortBy || STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD.sortBy;
    if (sortBy) {
      updateTSSDetailParams({
        sortBy: sortBy,
      });
    }
  };

  const applyFilter = (filterBy) => {
    let filterResult = { ...filterBy };
    if (
      "modIndicator" in filterResult &&
      filterResult.modIndicator[0] === undefined
    ) {
      filterResult.modIndicator = [];
    }
    if (INVOICE_INDICATOR_KEY in filterResult) {
      filterResult[INVOICE_INDICATOR_KEY] = filterResult[INVOICE_INDICATOR_KEY]
        ? "Y"
        : "";
    }
    updateTSSDetailParams({
      filterBy: {
        ...STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD.filterBy,
        ...filterResult,
      },
      pageNumber: 1,
    });
  };

  const enableAddEditStay = useMemo(() => {
    let canEnable = false;
    const onlyReviewOrPending =
      stayBatchDetail?.batchStatus === BATCH_STATUS_TYPE.REVIEW ||
      stayBatchDetail?.batchStatus === BATCH_STATUS_TYPE.PENDING;
    const hasProcessed =
      stayBatchDetail?.reviewerId === userInfo?.EID
        ? true
        : stayBatchDetail?.reviewerId?.length
        ? false
        : true;
    const authIndicator = userInfo?.authIndicator;

    if (
      onlyReviewOrPending &&
      hasProcessed &&
      authIndicator > USER_ACCESS_LEVEL.READ_ONLY &&
      (userInfo?.userLocation === USER_LOCATION.MARHQ ||
        userInfo?.userLocation === USER_LOCATION.OMARC ||
        isQ50Prop)
    ) {
      canEnable = true;
    }
    return canEnable;
  }, [isQ50Prop, stayBatchDetail, userInfo]);

  const updateTSSDetailParams = (params) => {
    dispatch(updateStaySummaryDetailParams({ ...stayTablePayload, ...params }));
  };

  const enableUserAccessSaveAll = enableAddEditStay,
    enableRowSave = enableAddEditStay;

  const summaryTableActionButtons = useMemo(() => {
    const startButton =
      enableAddEditStay && !isGroupBlock
        ? [
            {
              label: "Add a stay",
              actionEvent: addStay,
              className: `add_icon add_stay`,
            },
          ]
        : [];
    const endButton = enableUserAccessSaveAll
      ? [
          {
            label: SAVE_ALL_CHANGES,
            actionEvent: saveAllStay,
            disabled: !enableSaveAll,
            className: `save_all_icon save_all_stay`,
          },
        ]
      : [];
    return {
      start: startButton,
      end: endButton,
    };
  }, [enableAddEditStay, enableSaveAll, noPayModifiedSaveData, isGroupBlock]);

  const downloadXLS = () => {
    let fileURL = buildExportURL({
      url: `${API_URL.GET_SUMMARY_EXPORT}/${propertyCode}`,
      filterBy: {
        ...stayTablePayload.filterBy,
        ...{ batchNum1: stayTablePayload.batchNum1 },
        ...{ batchNum2: stayTablePayload.batchNum2 },
        ...{ batchType: stayBatchDetail.batchType },
        ...{ batchId: batchTablePayload.filterBy.batchId },
        ...{ batchDate: stayBatchDetail.batchDate },
        ...{ group: pathname.includes(GROUP_ROUTE_PATH) ? true : false },
      },
      currencyType: currencyType,
      group: isGroupBlock,
    });
    const batchNum1 = stayTablePayload?.batchNum1
      ? stayTablePayload.batchNum1.padStart(4, "0")
      : null;
    const batchNum2 = stayTablePayload?.batchNum2
      ? stayTablePayload?.batchNum2.padStart(4, "0")
      : null;
    const fileName = `Stays-${propertyCode}-${
      batchNum1 + batchNum2
    }-${formatDate(stayBatchDetail.batchDate, "DDMMMYY")}`;
    dispatch(getDownloadFile(fileURL, fileName));
  };

  useEffect(() => {
    if (serverError || overviewInternalError) {
      alertContextDispatch({
        type: "staySummeryServerErrorShow",
      });
    }
  }, [serverError, overviewInternalError]);

  useEffect(() => {
    if (batchTableInternalError) {
      alertContextDispatch({
        type: "staySummeryBatchTableErrorShow",
      });
    }
  }, [batchTableInternalError]);

  return (
    <div className="stay-summary-comp">
      {showStaySummaryLoader ? <LoadingBar global /> : null}
      <Prompt show={enablePrompt} actionCallBack={noPayPromptAction} />
      {staySummaryOverview &&
      staySummaryOverview !== null &&
      Object.keys(staySummaryOverview).length ? (
        <StaySummaryBatchOverview
          data={staySummaryOverview}
          column={3}
          hasMultipleCurrency={staySummaryOverview?.multipleCurrency}
          currencyType={currencyType}
        />
      ) : null}
      <SummaryFilter filterCallback={applyFilter} />
      <span
        className="card-link btn-link-color cursor-pointer"
        onClick={() => {
          if (isGroupBlock) {
            navigate("/groupblock", {
              state: {
                filterFromRoute: groupSlice.payloadForTable.filterBy,
                groupBlockId: stayBatchDetail.mhSeqNum,
              },
            });
          } else {
            navigate("/transient", {
              state: transientSlice.payloadForTable.filterBy,
            });
          }
        }}
      >
        <img
          alt="returnToBatchIcon"
          className=" m-2 icon-cls"
          src={returnToBatch}
        />
        {SS_RETURN_TO_BATCH}
      </span>
      <div className="d-flex flex-row justify-content-between mb-4">
        <div className="p-2">
          <h3 className="weight-700 mt-2">{STAY_SUMMARY}</h3>
          {stayBatchDetail?.groupBlockName ? (
            <p className="weight-700">{stayBatchDetail.groupBlockName}</p>
          ) : null}
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <button
            onClick={downloadXLS}
            className="download-btn btn btn-link text-decoration-none font-12 mt-auto py-0"
          >
            <i className="download-icon" /> <span>{DOWNLOAD_EXPORT_LABEL}</span>
          </button>
          <CurrencyButton
            showUSD={propertyDetails.currencyCode !== CURRENCY_TYPE.USD}
          />
        </div>
      </div>
      {showNoModification ? (
        <InfoBar variant="info" enableTimerClose={false}>
          {NO_MODIFICATION_ALLOWED_MSG}
        </InfoBar>
      ) : null}
      <div className="transient-batch-summery-table mb-4">
        <DataTableServerSide
          data={[{ ...stayBatchDetail }]}
          column={columnBatch}
          fixedWidthColumn={true}
          //sortCallback={applySort}
          //selectedRowCallback={selectedRowCallback}
          //isSelectAllDisabled={isSelectAllDisabled}
          //resetSelection={resetSelection}
        ></DataTableServerSide>
      </div>
      {transStaySummaryData?.length ? (
        <div className="mb-2">
          <Pagination
            className="pagination-bar"
            showOnlyInfo={true}
            currentPage={stayTablePayload.pageNumber}
            totalCount={staySummaryDetailTotalCount}
            pageSize={stayTablePayload.pageSize}
            pageinationInfoType={PAGINATION_LABEL_STAYS}
          />
        </div>
      ) : null}
      <div className="transient-stay-summery-table">
        <DataTableServerSide
          data={[...transStaySummaryData]}
          column={columnStay}
          enableRowEdit={true}
          enableRowSave={enableRowSave}
          saveButtonIndex={17}
          saveActionCallback={saveNoPay}
          dynamicColumnWidth={30}
          fixedWidthColumn={true}
          actionButtons={summaryTableActionButtons}
          rowEditCallback={editStay}
          sortCallback={applySort}
          //selectedRowCallback={selectedRowCallback}
          //isSelectAllDisabled={isSelectAllDisabled}
          //resetSelection={resetSelection}
        ></DataTableServerSide>
        {modalShow && (
          <AddStayModal
            modalShow={modalShow}
            handleAddStayClose={handleAddStayClose}
          />
        )}
        {showEditModal && (
          <EditStay
            modalShow={showEditModal}
            handleEditStayClose={handleEditStayClose}
            rowData={editRowData}
            isGroupBlock={isGroupBlock}
          />
        )}
        {showLinkedStay && (
          <LinkedStayModel
            modalShow={showLinkedStay}
            closeLinkStay={closeLinkStay}
            editRow={linkRowData}
            stayBatchDetail={stayBatchDetail}
            continueEditCallback={openEditStay}
          />
        )}
      </div>
      {transStaySummaryData?.length ? (
        <div>
          <Pagination
            className="pagination-bar"
            currentPage={stayTablePayload.pageNumber}
            totalCount={staySummaryDetailTotalCount}
            pageSize={stayTablePayload.pageSize}
            pageinationInfoType={SS_STAYS}
            onItemPerPageChange={(pageSize, resetPage) => {
              scrollToTop(500);
              if (resetPage) {
                updateTSSDetailParams({
                  pageNumber: resetPage,
                  pageSize,
                });
              } else {
                updateTSSDetailParams({
                  pageNumber: stayTablePayload.pageNumber,
                  pageSize,
                });
              }
            }}
            onPageChange={(page) => {
              scrollToTop(500);
              updateTSSDetailParams({
                pageNumber: page,
                pageSize: stayTablePayload.pageSize,
              });
            }}
          />
        </div>
      ) : (
        <p className="ms-2">{LANDING_NO_DATA_FOUND}</p>
      )}
    </div>
  );
}
